import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Title } from 'components/atoms';

// Generate Project Data
function createData(id: number, date: string, name: string, budget: number) {
  return { id, date, name, budget };
}

const rows = [
  createData(0, '16 Jul, 2024', 'Nam aliquam', 312.44),
  createData(1, '16 Jul, 2024', 'Proin quis', 866.99),
  createData(2, '16 Jul, 2024', 'Vivamus cursus', 100.81),
  createData(3, '16 Jul, 2024', 'Aenean imperdiet', 654.39),
  createData(4, '15 Jul, 2024', 'Etiam sollicitudin', 212.79),
];

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Projects() {
  return (
    <>
      <Title>Projects</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Budget</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell align="right">{`₹${row.budget}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more projects
      </Link>
    </>
  );
}
