import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedTimeSheetModel {
  rows: TimeSheetModel[];
  total: number;
}

export interface TimeSheetModel {
  id: number;
  branchId: number;
  employeeId: number;
  verifierId: number;
  accountId: number;
  projectId: number;
  customerId: number;
  supplierId: number;
  vehicleId: number;
  inventoryId: number;
  timeSheetType: string;
  status: string;
  day: number;
  hour0: number;
  hour1: number;
  hour2: number;
  hour3: number;
  hour4: number;
  hour5: number;
  hour6: number;
  hour7: number;
  hour8: number;
  hour9: number;
  hour10: number;
  hour11: number;
  hour12: number;
  hour13: number;
  hour14: number;
  hour15: number;
  hour16: number;
  hour17: number;
  hour18: number;
  hour19: number;
  hour20: number;
  hour21: number;
  hour22: number;
  hour23: number;
  notes: string;
  createdAt: number;
  updatedAt: number;
}

export const getTimeSheets = async (
  paramObj: object = {},
): Promise<PaginatedTimeSheetModel> => {
  const response = await axios.get<PaginatedTimeSheetModel>(`/time_sheets`, {
    params: paramObj,
  });
  return response.data;
};

export const getTimeSheetById = async (id: number): Promise<TimeSheetModel> => {
  const response = await axios.get<TimeSheetModel>(`/time_sheets/${id}`);
  return response.data;
};

export const createTimeSheet = async (
  timeSheetData: Omit<TimeSheetModel, 'id'>,
): Promise<TimeSheetModel> => {
  const response = await axios.post<TimeSheetModel>(
    `/time_sheets`,
    timeSheetData,
  );
  return response.data;
};

export const updateTimeSheet = async (
  id: GridRowId,
  timeSheetData: Partial<TimeSheetModel>,
): Promise<TimeSheetModel> => {
  const response = await axios.put<TimeSheetModel>(
    `/time_sheets/${id}`,
    timeSheetData,
  );
  return response.data;
};

export const deleteTimeSheet = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/time_sheets/${id}`);
};
