import React, { useState } from 'react';
import _ from 'lodash';

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { OperationModel } from 'apis/OperationApi';
import { FormModeType } from './Operations';
import { useLoader } from 'contexts/LoaderContext';
import { OperationFormTypeOptions } from 'constants/DropDownOptions';
import { OptionsType } from 'apis/ListApi';

interface OperationFormType {
  mode: FormModeType | undefined;
  initFormState: Partial<OperationModel>;
  employeesList: OptionsType[];
  projectsList: OptionsType[];
  customersList: OptionsType[];
  suppliersList: OptionsType[];
  vehiclesList: OptionsType[];
  inventoriesList: OptionsType[];
  callbackFn: (operation: Partial<OperationModel>) => void;
  handleCloseFn: () => void;
}

const OperationForm = (props: OperationFormType) => {
  const [formState, setFormState] = useState(props.initFormState);
  const { loading } = useLoader();
  const disabled = props.mode === 'delete';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAutocompleteChange = (name: string, value: string) => {
    setFormState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.callbackFn(formState as Partial<OperationModel>);
  };

  const btnName = () => {
    switch (props.mode) {
      case 'create':
        return 'Add';
      case 'edit':
        return 'Save';
      case 'delete':
        return 'Delete';
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.inventoriesList.find(entry => {
                return entry.id == formState?.inventoryId?.toString() || '';
              })}
              renderInput={params => (
                <TextField {...params} label="Inventory" />
              )}
              options={
                !props.inventoriesList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.inventoriesList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('inventoryId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="type-select-label">
              {disabled ? 'Type' : 'Type *'}
            </InputLabel>
            <Select
              label="Type"
              labelId="type-select-label"
              id="type-select"
              name="operationType"
              value={formState.operationType}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(OperationFormTypeOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.projectsList.find(entry => {
                return entry.id == formState?.projectId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Project" />}
              options={
                !props.projectsList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.projectsList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('projectId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.customersList.find(entry => {
                return entry.id == formState?.customerId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Customer" />}
              options={
                !props.customersList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.customersList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('customerId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.suppliersList.find(entry => {
                return entry.id == formState?.supplierId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Supplier" />}
              options={
                !props.suppliersList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.suppliersList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('supplierId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.vehiclesList.find(entry => {
                return entry.id == formState?.vehicleId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Vehicle" />}
              options={
                !props.vehiclesList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.vehiclesList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('vehicleId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Quantity"
              name="quantity"
              type="number"
              autoFocus
              value={formState.quantity}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Price"
              name="price"
              type="number"
              autoFocus
              value={formState.price}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} pb={1}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Notes"
              name="notes"
              value={formState.notes}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} textAlign={'right'} pt={1}>
          <Button onClick={props.handleCloseFn} variant="text" sx={{ mr: 4 }}>
            {'Close'}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ px: 6, borderRadius: 2 }}
            loading={loading}
            loadingIndicator={'Loading...'}>
            {btnName()}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(OperationForm);
