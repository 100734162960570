import { createTheme } from '@mui/material';

import lightThemeOptions from './lightTheme';
import components from './components';
import darkThemeOptions from './darkTheme';

const lightTheme = createTheme({
  ...lightThemeOptions,
  ...components,
});

const darkTheme = createTheme({
  ...darkThemeOptions,
  ...components,
});

export { lightTheme, darkTheme };
