import React, { useState } from 'react';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import {
  Box,
  Divider,
  IconButton,
  styled,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  Logout as LogoutIcon,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from '@mui/icons-material';

import { Copyright } from 'components/atoms';
import DrawerList from './DrawerList';
import { useLoader } from 'contexts/LoaderContext';
import { useAuth } from 'contexts/AuthContext';

const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const DrawerLayout = () => {
  const { logout } = useAuth();
  const { showLoader, hideLoader } = useLoader();

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState(largeScreen);
  const [appBarHeader, setAppBarHeader] = useState('KAMALAMS');

  const navigate = useNavigate();

  const updateAppBarHeader = (title: string) => {
    setAppBarHeader(title);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="absolute" open={open}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: largeScreen ? 3 : 1,
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}>
            {appBarHeader}
          </Typography>
          <Tooltip title="Logout" arrow>
            <IconButton
              color="inherit"
              onClick={() => {
                showLoader();
                logout().finally(() => {
                  hideLoader();
                  navigate('/');
                });
              }}>
              {/* TODO: basic logout mock - update after API integration */}
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" anchor="left" open={open}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={toggleDrawer}>
            <MenuOpenIcon />
          </IconButton>

          {/* TODO: Application name to an atom ? or along with Logo with a condition for name */}
          <Typography sx={{ pl: 2 }}>KAMALAMS</Typography>
        </Toolbar>

        <Divider />

        <DrawerList callBackFn={updateAppBarHeader} />
      </Drawer>

      <Box
        component="main"
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}>
        <Toolbar />
        <Outlet />
        <Copyright sx={{ pt: 4 }} />
      </Box>
    </Box>
  );
};

export default React.memo(DrawerLayout);
