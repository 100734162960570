import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import { Copyright } from 'components/atoms';
import { AlertPopup } from 'components';
import { useLoader } from 'contexts/LoaderContext';

import { LoginReqType, useAuth } from 'contexts/AuthContext';

const Login = () => {
  const { authCheck, login } = useAuth();

  const [loginData, setLoginData] = useState<LoginReqType>();
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const { showLoader, hideLoader } = useLoader();
  const [authLoading, setAuthLoading] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setShowErrorMessage(false);
  };

  useEffect(() => {
    setAuthLoading(true);
    authCheck()
      .then(response => {
        if (response) {
          navigate('/app/projects');
        }
      })
      .finally(() => {
        setAuthLoading(false);
      });
  }, []);

  useEffect(() => {
    if (loginData) {
      setLoadingBtn(true);
      showLoader();
      login(loginData)
        .then(response => {
          if (response) {
            navigate('/app/projects');
          } else {
            setShowErrorMessage(true);
            setErrorMessage('Login failed!');
          }
        })
        .catch(error => {
          setShowErrorMessage(true);
          setErrorMessage('Login failed!');
        })
        .finally(() => {
          hideLoader();
          setLoadingBtn(false);
        });
    }
  }, [loginData]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // data.set('username', 'demo@habiot.com');
    // data.set('password', 'demo123');
    // data.set('branch', 'DEM');

    setLoginData({
      email: data.get('username'),
      password: data.get('password'),
      branchCode: _.upperCase(data.get('branch') as string),
      deviceId: '*',
    } as LoginReqType);
  };

  if (authLoading) {
    return (
      <Paper
        elevation={0}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress color="primary" />
      </Paper>
    );
  }

  return (
    <Grid
      container
      justifyContent={'center'}
      alignContent={'center'}
      height={'100vh'}
      width={'100vw'}
      sx={{
        background: `url(${require('assets/logo/main-bg.jpeg')})`,
      }}>
      <Helmet>
        <title>SKC - Login</title>
      </Helmet>
      <Grid item xs={10} md={4} lg={3}>
        <Card
          sx={{
            borderRadius: 4,
            boxShadow: 3,
            backdropFilter: 'blur(10px)',
            background: 'rgba(0,0,0,0.1)',
          }}>
          <Grid
            container
            px={{ xs: 3, md: 3, lg: 4 }}
            py={{ xs: 2, md: 2, lg: 3 }}>
            <Grid item xs={12} textAlign={'center'}>
              <Typography
                variant="h5"
                fontWeight={700}
                mb={1}
                color={'secondary.main'}>
                Shree Kumaran Constructions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  required
                  margin="normal"
                  fullWidth
                  id="username"
                  color="secondary"
                  label="Username"
                  name="username"
                  autoFocus
                  inputProps={{
                    style: {
                      color: 'white',
                    },
                  }}
                  sx={{
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'white',
                    },
                    '& label': {
                      color: 'lightgrey',
                    },
                    '&:hover label': {
                      color: 'white',
                    },
                    '& label.Mui-focused': {
                      color: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'lightgrey',
                      },
                      '&:hover fieldset': {
                        borderColor: 'white',
                      },
                    },
                  }}
                />
                <TextField
                  required
                  margin="normal"
                  fullWidth
                  color="secondary"
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  inputProps={{
                    style: {
                      color: 'white',
                    },
                  }}
                  sx={{
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'white',
                    },
                    '& label': {
                      color: 'lightgrey',
                    },
                    '&:hover label': {
                      color: 'white',
                    },
                    '& label.Mui-focused': {
                      color: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'lightgrey',
                      },
                      '&:hover fieldset': {
                        borderColor: 'white',
                      },
                    },
                  }}
                />
                <TextField
                  required
                  margin="normal"
                  fullWidth
                  name="branch"
                  label="Branch"
                  id="branch"
                  color="secondary"
                  inputProps={{
                    maxLength: 3,
                    style: {
                      color: 'white',
                      textTransform: 'uppercase',
                    },
                  }}
                  sx={{
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'white',
                    },
                    '& label': {
                      color: 'lightgrey',
                    },
                    '&:hover label': {
                      color: 'white',
                    },
                    '& label.Mui-focused': {
                      color: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'lightgrey',
                      },
                      '&:hover fieldset': {
                        borderColor: 'white',
                      },
                    },
                  }}
                />
                <LoadingButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ mt: 3, borderRadius: 2, p: 1.5 }}
                  loading={loadingBtn}
                  loadingIndicator="Signing In...">
                  Sign In
                </LoadingButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Copyright sx={{ mt: 3 }} color={'secondary'} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {errorMessage && (
        <AlertPopup
          type="error"
          open={showErrorMessage}
          message={errorMessage}
          onClose={handleClose}
        />
      )}
    </Grid>
  );
};

export default React.memo(Login);
