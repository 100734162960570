import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  createReminder,
  deleteReminder,
  ReminderModel,
  getReminders,
  updateReminder,
} from 'apis/ReminderApi';
import { useLoader } from 'contexts/LoaderContext';
import ReminderForm from './ReminderForm';
import axios from 'apis/AxiosConfig';
import {
  listAccounts,
  listCustomers,
  listEmployees,
  listInventories,
  listProjects,
  listSuppliers,
  listVehicles,
  OptionsType,
} from 'apis/ListApi';
import { pageSizeOptions } from 'constants/PaginationOptions';
import { ReminderFormStatusOptions } from 'constants/DropDownOptions';
import { CurrentDateTime, DateTimeFormat } from 'utils/EpochConverter';
import { useAuth } from 'contexts/AuthContext';

export type FormModeType = 'create' | 'edit' | 'delete';

const Reminders = () => {
  const { branch, employee } = useAuth();
  const { loading, showLoader, hideLoader } = useLoader();

  const [selectedReminderID, setSelectedReminderID] = useState<GridRowId>();
  const [remindersData, setRemindersData] = useState<ReminderModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const [accountsList, setAccountsList] = useState<OptionsType[]>([]);
  const [employeesList, setEmployeesList] = useState<OptionsType[]>([]);
  const [projectsList, setProjectsList] = useState<OptionsType[]>([]);
  const [customersList, setCustomersList] = useState<OptionsType[]>([]);
  const [suppliersList, setSuppliersList] = useState<OptionsType[]>([]);
  const [vehiclesList, setVehiclesList] = useState<OptionsType[]>([]);
  const [inventoriesList, setInventoriesList] = useState<OptionsType[]>([]);

  const fetchData = () => {
    showLoader();
    getReminders({
      branchId: branch?.id,
      page: page + 1,
      pageSize: pageSize,
      sort: sortColumn + ':' + sortOrder,
      filter: filterField + ':' + filterOperator + ':' + filterValue,
    })
      .then(response => {
        setRemindersData(response.rows);
        setRowCount(response.total);
      })
      .catch(error => console.error('Error Fetching Reminders: ', error))
      .finally(() => hideLoader());
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  useEffect(() => {
    axios
      .all([
        listAccounts({ branchId: branch?.id }),
        listEmployees({ branchId: branch?.id }),
        listProjects({ branchId: branch?.id }),
        listCustomers({ branchId: branch?.id }),
        listSuppliers({ branchId: branch?.id }),
        listVehicles({ branchId: branch?.id }),
        listInventories({ branchId: branch?.id }),
      ])
      .then(
        axios.spread(
          (
            accountsList,
            employeesList,
            projectsList,
            customersList,
            suppliersList,
            vehiclesList,
            inventoriesList,
          ) => {
            setAccountsList(accountsList);
            setEmployeesList(employeesList);
            setProjectsList(projectsList);
            setCustomersList(customersList);
            setSuppliersList(suppliersList);
            setVehiclesList(vehiclesList);
            setInventoriesList(inventoriesList);
          },
        ),
      );
  }, []);

  const handleAddReminder = (newReminder: Partial<ReminderModel>) => {
    showLoader();
    createReminder(newReminder as Omit<ReminderModel, 'id'>)
      .then(response => {
        setRemindersData(prevReminders => [...prevReminders, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding reminder: ', error))
      .finally(() => hideLoader());
  };

  const handleEditReminder = (editedReminder: Partial<ReminderModel>) => {
    showLoader();
    if (selectedReminderID) {
      updateReminder(selectedReminderID, editedReminder)
        .then(response => {
          setRemindersData(
            _.chain(remindersData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(reminders => {
                const index = _.findIndex(
                  reminders,
                  rem => rem.id === selectedReminderID,
                );
                if (index !== -1) reminders[index] = response;
                return reminders;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing reminder: ', error))
        .finally(() => hideLoader());
    }
  };

  const handleDeleteReminder = () => {
    if (selectedReminderID) {
      showLoader();
      deleteReminder(selectedReminderID)
        .then(() => {
          setRemindersData(
            _.reject(remindersData, {
              id: selectedReminderID,
            }) as ReminderModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting reminder: ', error))
        .finally(() => hideLoader());
    }
  };

  const openReminderForm = () => {
    let title: string;
    let currentReminder: Partial<ReminderModel> = {
      branchId: branch?.id,
      accountId: 0,
      employeeId: employee?.id,
      projectId: 0,
      customerId: 0,
      supplierId: 0,
      vehicleId: 0,
      inventoryId: 0,
      name: '',
      notes: '',
      time: CurrentDateTime(),
      status: '',
    };
    let callback: (reminder: Partial<ReminderModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Reminder';
        callback = handleAddReminder;
        break;
      case 'edit':
        title = 'Edit Reminder';
        callback = handleEditReminder;
        currentReminder = _.chain(remindersData)
          .find(reminder => reminder.id === selectedReminderID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Reminder';
        callback = handleDeleteReminder;
        currentReminder = _.chain(remindersData)
          .find(reminder => reminder.id === selectedReminderID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Reminder';
        callback = handleAddReminder;
    }
    return (
      <Dialog
        open={openForm}
        fullScreen={fullScreen}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: fullScreen ? 0 : 4 } }}
        sx={{ backdropFilter: 'blur(2px)' }}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <ReminderForm
            mode={formMode}
            initFormState={currentReminder}
            accountsList={accountsList}
            employeesList={employeesList}
            projectsList={projectsList}
            customersList={customersList}
            suppliersList={suppliersList}
            vehiclesList={vehiclesList}
            inventoriesList={inventoriesList}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1,
      type: 'string',
    },
    {
      field: 'time',
      headerName: 'TIME',
      flex: 0.8,
      filterable: false,
      valueGetter: value => value && DateTimeFormat(value),
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(ReminderFormStatusOptions, record => {
        return record.value;
      }),
      getOptionLabel: value => {
        const result = _.find(ReminderFormStatusOptions, record => {
          return record.value == (value as string);
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ACTIONS',
      flex: 0.4,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              setSelectedReminderID(id);
              handleOpen('edit');
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            color="inherit"
            onClick={() => {
              setSelectedReminderID(id);
              handleOpen('delete');
            }}
          />,
        ];
      },
    },
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ pb: 1 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: 2 }}
          onClick={() => handleOpen('create')}>
          <AddIcon /> Add
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>Reminders</title>
      </Helmet>

      <Paper sx={{ height: '50%', minHeight: 400, borderRadius: 4 }}>
        <DataGrid
          loading={loading}
          rows={remindersData}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableRowSelectionOnClick
          sx={{ px: 2, pt: 2, borderRadius: 4 }}
        />
      </Paper>

      {openForm && openReminderForm()}
    </>
  );
};

export default React.memo(Reminders);
