import React, { useState } from 'react';

import { Box, Button, FormControl, Grid, TextField } from '@mui/material';

import { CustomerModel } from 'apis/CustomerApi';
import { FormModeType } from './Customers';
import { useLoader } from 'contexts/LoaderContext';
import { LoadingButton } from '@mui/lab';

interface CustomerFormType {
  mode: FormModeType | undefined;
  initFormState: Partial<CustomerModel>;
  callbackFn: (customer: Partial<CustomerModel>) => void;
  handleCloseFn: () => void;
}

const CustomerForm = (props: CustomerFormType) => {
  const [formState, setFormState] = useState(props.initFormState);
  const { loading } = useLoader();
  const disabled = props.mode === 'delete';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeUpperCase = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value.toUpperCase(),
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.callbackFn(formState as Partial<CustomerModel>);
  };

  const btnName = () => {
    switch (props.mode) {
      case 'create':
        return 'Add';
      case 'edit':
        return 'Save';
      case 'delete':
        return 'Delete';
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              type="text"
              name="name"
              autoFocus
              value={formState.name}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Phone 1"
              type="tel"
              name="phone1"
              value={formState.phone1}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 10,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Phone 2"
              type="tel"
              name="phone2"
              value={formState.phone2}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 10,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Phone 3"
              type="tel"
              name="phone3"
              value={formState.phone3}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 10,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Address"
              name="address"
              value={formState.address}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="GSTIN"
              type="text"
              name="gstIn"
              value={formState.gstIn}
              onChange={handleChangeUpperCase}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 15,
                style: {
                  textTransform: 'uppercase',
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Balance"
              type="number"
              name="balance"
              value={formState.balance}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Limit"
              type="number"
              name="limit"
              value={formState.limit}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="GST"
              type="number"
              name="gst"
              value={formState.gst}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="CGST"
              type="number"
              name="cGst"
              value={formState.cGst}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="SGST"
              type="number"
              name="sGst"
              value={formState.sGst}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="IGST"
              type="number"
              name="iGst"
              value={formState.iGst}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} pb={1}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Notes"
              name="notes"
              value={formState.notes}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} textAlign={'right'} pt={1}>
          <Button onClick={props.handleCloseFn} variant="text" sx={{ mr: 4 }}>
            {'Close'}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ px: 6, borderRadius: 2 }}
            loading={loading}
            loadingIndicator={'Loading...'}>
            {btnName()}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(CustomerForm);
