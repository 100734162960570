import React, { useState } from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment';

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { ReminderModel } from 'apis/ReminderApi';
import { FormModeType } from './Reminders';
import { useLoader } from 'contexts/LoaderContext';
import { ReminderFormStatusOptions } from 'constants/DropDownOptions';
import { DateTimePicker } from '@mui/x-date-pickers';
import { OptionsType } from 'apis/ListApi';
import { DateTimeFormatString } from 'constants/DateTimeFormat';

interface ReminderFormType {
  mode: FormModeType | undefined;
  initFormState: Partial<ReminderModel>;
  accountsList: OptionsType[];
  employeesList: OptionsType[];
  projectsList: OptionsType[];
  customersList: OptionsType[];
  suppliersList: OptionsType[];
  vehiclesList: OptionsType[];
  inventoriesList: OptionsType[];
  callbackFn: (reminder: Partial<ReminderModel>) => void;
  handleCloseFn: () => void;
}

const ReminderForm = (props: ReminderFormType) => {
  const [formState, setFormState] = useState(props.initFormState);
  const { loading } = useLoader();
  const disabled = props.mode === 'delete';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (name: string) => (newDate: Moment | null) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: newDate?.unix(),
    }));
  };

  const handleAutocompleteChange = (name: string, value: string) => {
    setFormState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.callbackFn(formState as Partial<ReminderModel>);
  };

  const btnName = () => {
    switch (props.mode) {
      case 'create':
        return 'Add';
      case 'edit':
        return 'Save';
      case 'delete':
        return 'Delete';
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={8}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              name="name"
              autoFocus
              value={formState.name}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="status-select-label">
              {disabled ? 'Status' : 'Status *'}
            </InputLabel>
            <Select
              label="Status"
              labelId="status-select-label"
              id="status-select"
              name="status"
              value={formState.status}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(ReminderFormStatusOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DateTimePicker
              label="Time"
              name="time"
              value={moment.unix(formState.time as unknown as number)}
              format={DateTimeFormatString}
              onChange={handleDateChange('time')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.accountsList.find(entry => {
                return entry.id == formState?.accountId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Account" />}
              options={
                !props.accountsList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.accountsList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('accountId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.employeesList.find(entry => {
                return entry.id == formState?.employeeId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Employee" />}
              options={
                !props.employeesList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.employeesList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('employeeId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.projectsList.find(entry => {
                return entry.id == formState?.projectId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Project" />}
              options={
                !props.projectsList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.projectsList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('projectId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.customersList.find(entry => {
                return entry.id == formState?.customerId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Customer" />}
              options={
                !props.customersList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.customersList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('customerId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.suppliersList.find(entry => {
                return entry.id == formState?.supplierId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Supplier" />}
              options={
                !props.suppliersList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.suppliersList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('supplierId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.vehiclesList.find(entry => {
                return entry.id == formState?.vehicleId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Vehicle" />}
              options={
                !props.vehiclesList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.vehiclesList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('vehicleId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.inventoriesList.find(entry => {
                return entry.id == formState?.inventoryId?.toString() || '';
              })}
              renderInput={params => (
                <TextField {...params} label="Inventory" />
              )}
              options={
                !props.inventoriesList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.inventoriesList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('inventoryId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} pb={1}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Notes"
              name="notes"
              value={formState.notes}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} textAlign={'right'} pt={1}>
          <Button onClick={props.handleCloseFn} variant="text" sx={{ mr: 4 }}>
            {'Close'}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ px: 6, borderRadius: 2 }}
            loading={loading}
            loadingIndicator={'Loading...'}>
            {btnName()}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(ReminderForm);
