import React from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Paper
      elevation={0}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: '-webkit-center',
      }}>
      <Grid>
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight={700} px={3}>
            Oops! The page you&apos;re looking for doesn&apos;t exist.
          </Typography>
        </Grid>
        <Grid item xs={12} p={2} pt={4} sx={{ width: '70%', minWidth: 300 }}>
          <Typography variant="subtitle1" fontSize={16}>
            You might have mistyped the address, or the page has been moved, or
            your account lacks the necessary permissions.
          </Typography>
        </Grid>
        <Grid item xs={12} p={2}>
          <Button onClick={() => navigate('/')} variant="outlined">
            {'Go Home'}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default React.memo(NotFound);
