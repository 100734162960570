import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedCustomerModel {
  rows: CustomerModel[];
  total: number;
}

export interface CustomerModel {
  id: number;
  branchId: number;
  name: string;
  phone1: string;
  phone2: string;
  phone3: string;
  address: string;
  gstIn: string;
  balance: number;
  limit: number;
  gst: number;
  iGst: number;
  cGst: number;
  sGst: number;
  notes: string;
  createdAt: number;
  updatedAt: number;
}

export const getCustomers = async (
  paramObj: object = {},
): Promise<PaginatedCustomerModel> => {
  const response = await axios.get<PaginatedCustomerModel>(`/customers`, {
    params: paramObj,
  });
  return response.data;
};

export const getCustomerById = async (id: number): Promise<CustomerModel> => {
  const response = await axios.get<CustomerModel>(`/customers/${id}`);
  return response.data;
};

export const createCustomer = async (
  customerData: Omit<CustomerModel, 'id'>,
): Promise<CustomerModel> => {
  const response = await axios.post<CustomerModel>(`/customers`, customerData);
  return response.data;
};

export const updateCustomer = async (
  id: GridRowId,
  customerData: Partial<CustomerModel>,
): Promise<CustomerModel> => {
  const response = await axios.put<CustomerModel>(
    `/customers/${id}`,
    customerData,
  );
  return response.data;
};

export const deleteCustomer = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/customers/${id}`);
};
