import axios from 'apis/AxiosConfig';
import _ from 'lodash';

export type OptionsType = {
  id: string;
  name: string;
};

const mapObjectToOptionsType = (obj: unknown): OptionsType[] =>
  _.map(obj as never, (value: string, key: string) => {
    return { id: key, name: value };
  });

export const listAccounts = async (
  paramObj: object = {},
): Promise<OptionsType[]> => {
  const response = await axios.get<OptionsType[]>(`/list/accounts`, {
    params: paramObj,
  });
  return mapObjectToOptionsType(response.data);
};

export const listBranches = async (
  paramObj: object = {},
): Promise<OptionsType[]> => {
  const response = await axios.get<OptionsType[]>(`/list/branches`, {
    params: paramObj,
  });
  return mapObjectToOptionsType(response.data);
};

export const listEmployees = async (
  paramObj: object = {},
): Promise<OptionsType[]> => {
  const response = await axios.get<OptionsType[]>(`/list/employees`, {
    params: paramObj,
  });
  return mapObjectToOptionsType(response.data);
};

export const listProjects = async (
  paramObj: object = {},
): Promise<OptionsType[]> => {
  const response = await axios.get<OptionsType[]>(`/list/projects`, {
    params: paramObj,
  });
  return mapObjectToOptionsType(response.data);
};

export const listReminders = async (
  paramObj: object = {},
): Promise<OptionsType[]> => {
  const response = await axios.get<OptionsType[]>(`/list/reminders`, {
    params: paramObj,
  });
  return mapObjectToOptionsType(response.data);
};

export const listCustomers = async (
  paramObj: object = {},
): Promise<OptionsType[]> => {
  const response = await axios.get<OptionsType[]>(`/list/customers`, {
    params: paramObj,
  });
  return mapObjectToOptionsType(response.data);
};

export const listSuppliers = async (
  paramObj: object = {},
): Promise<OptionsType[]> => {
  const response = await axios.get<OptionsType[]>(`/list/suppliers`, {
    params: paramObj,
  });
  return mapObjectToOptionsType(response.data);
};

export const listTimeSheets = async (
  paramObj: object = {},
): Promise<OptionsType[]> => {
  const response = await axios.get<OptionsType[]>(`/list/time_sheets`, {
    params: paramObj,
  });
  return mapObjectToOptionsType(response.data);
};

export const listTransactions = async (
  paramObj: object = {},
): Promise<OptionsType[]> => {
  const response = await axios.get<OptionsType[]>(`/list/transactions`, {
    params: paramObj,
  });
  return mapObjectToOptionsType(response.data);
};

export const listVehicles = async (
  paramObj: object = {},
): Promise<OptionsType[]> => {
  const response = await axios.get<OptionsType[]>(`/list/vehicles`, {
    params: paramObj,
  });
  return mapObjectToOptionsType(response.data);
};

export const listInventories = async (
  paramObj: object = {},
): Promise<OptionsType[]> => {
  const response = await axios.get<OptionsType[]>(`/list/inventories`, {
    params: paramObj,
  });
  return mapObjectToOptionsType(response.data);
};
