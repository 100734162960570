import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedTransactionModel {
  rows: TransactionModel[];
  total: number;
}

export interface TransactionModel {
  id: number;
  branchId: number;
  projectId: number;
  employeeId: number;
  accountId: number;
  customerId: number;
  supplierId: number;
  vehicleId: number;
  inventoryId: number;
  category: string;
  transactionType: string;
  transactionStatus: string;
  gstIn: string;
  amount: number;
  gst: number;
  iGst: number;
  cGst: number;
  sGst: number;
  notes: string;
  createdAt: number;
  updatedAt: number;
}

export const getTransactions = async (
  paramObj: object = {},
): Promise<PaginatedTransactionModel> => {
  const response = await axios.get<PaginatedTransactionModel>(`/transactions`, {
    params: paramObj,
  });
  return response.data;
};

export const getTransactionById = async (
  id: number,
): Promise<TransactionModel> => {
  const response = await axios.get<TransactionModel>(`/transactions/${id}`);
  return response.data;
};

export const createTransaction = async (
  transactionData: Omit<TransactionModel, 'id'>,
): Promise<TransactionModel> => {
  const response = await axios.post<TransactionModel>(
    `/transactions`,
    transactionData,
  );
  return response.data;
};

export const updateTransaction = async (
  id: GridRowId,
  transactionData: Partial<TransactionModel>,
): Promise<TransactionModel> => {
  const response = await axios.put<TransactionModel>(
    `/transactions/${id}`,
    transactionData,
  );
  return response.data;
};

export const deleteTransaction = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/transactions/${id}`);
};
