import { BranchModel } from 'apis/BranchApi';
import { EmployeeModel } from 'apis/EmployeeApi';

export type StateType = {
  branch: BranchModel | null;
  employee: EmployeeModel | null;
  isAuthenticated: boolean;
};

export type ActionType =
  | { type: 'SET_BRANCH'; payload: StateType['branch'] }
  | { type: 'SET_EMPLOYEE'; payload: StateType['employee'] }
  | { type: 'SET_AUTHENTICATION'; payload: boolean };

export const initialState: StateType = {
  branch: null,
  employee: null,
  isAuthenticated: false,
};

const globalReducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'SET_BRANCH':
      return { ...state, branch: action.payload };
    case 'SET_EMPLOYEE':
      return { ...state, employee: action.payload };
    case 'SET_AUTHENTICATION':
      return { ...state, isAuthenticated: action.payload };
    default:
      return state;
  }
};

export default globalReducer;
