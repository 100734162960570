import {
  AccountBalance as AccountIcon,
  AssignmentInd as EmployeeIcon,
  CurrencyRupee as TransactionIcon,
  Groups as CustomerIcon,
  Inventory as InventoryIcon,
  Layers as ProjectIcon,
  LocalShipping as VehicleIcon,
  NotificationsActive as ReminderIcon,
  PointOfSale as OperationIcon,
  Store as SupplierIcon,
  Today as TimeSheetIcon,
} from '@mui/icons-material';
import {
  Accounts,
  Customers,
  Employees,
  Inventories,
  Operations,
  Projects,
  Reminders,
  Suppliers,
  TimeSheets,
  Transactions,
  Vehicles,
} from 'pages';

export const EnabledPages = [
  {
    title: 'Projects',
    url: '/app/projects',
    icon: ProjectIcon,
    component: Projects,
    disabled: false,
  },
  {
    title: 'Transactions',
    url: '/app/transactions',
    icon: TransactionIcon,
    component: Transactions,
    disabled: false,
  },
  {
    title: 'Customers',
    url: '/app/customers',
    icon: CustomerIcon,
    component: Customers,
    disabled: true,
  },
  {
    title: 'Suppliers',
    url: '/app/suppliers',
    icon: SupplierIcon,
    component: Suppliers,
    disabled: true,
  },
  {
    title: 'Inventory',
    url: '/app/inventory',
    icon: InventoryIcon,
    component: Inventories,
    disabled: true,
  },
  {
    title: 'Operations',
    url: '/app/operations',
    icon: OperationIcon,
    component: Operations,
    disabled: true,
  },
  {
    title: 'Time Sheets',
    url: '/app/timesheets',
    icon: TimeSheetIcon,
    component: TimeSheets,
    disabled: false,
  },
  {
    title: 'Reminders',
    url: '/app/reminders',
    icon: ReminderIcon,
    component: Reminders,
    disabled: false,
  },
  {
    title: 'Accounts',
    url: '/app/accounts',
    icon: AccountIcon,
    component: Accounts,
    disabled: false,
  },
  {
    title: 'Vehicles',
    url: '/app/vehicles',
    icon: VehicleIcon,
    component: Vehicles,
    disabled: true,
  },
  {
    title: 'Employees',
    url: '/app/employees',
    icon: EmployeeIcon,
    component: Employees,
    disabled: false,
  },
];
