export const AcceptMimeType = (fileType: string) => {
  switch (fileType) {
    case 'image':
      return 'image/*';
    case 'video':
      return 'video/*';
    case 'audio':
      return 'audio/*';
    case 'pdf':
      return 'application/pdf';
    case 'document':
      return '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'spreadsheet':
      return 'text/csv,.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'archive':
      return '.zip,.rar,.7z';
    case 'text':
      return 'text/plain';
    default:
      return '';
  }
};
