import React, { useState } from 'react';
import _ from 'lodash';

import {
  Button,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';

import { AcceptMimeType } from 'utils/AcceptMimeType';
import { FileResType } from 'apis/FileApi';
import { ConfirmDialog } from 'components';

interface FileUploadType {
  mode: string | undefined;
  disabled: boolean;
  localFiles: File[];
  apiFiles: FileResType[];
  handleFileChangeFn: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileDeleteFn: (fileToDelete: File | FileResType) => void;
}

const FileUpload = (props: FileUploadType) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = useState<File | FileResType>();

  const triggerConfirmDialog = (file: File | FileResType) => {
    setFileToDelete(file);
    setOpenConfirmDialog(true);
  };

  const onConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const onConfirmDialogConfirm = () => {
    setOpenConfirmDialog(false);
    if (fileToDelete) {
      props.handleFileDeleteFn(fileToDelete);
    }
  };

  const handleOpenFile = (file: FileResType) => {
    if (file.url) {
      const newWindow = window.open(file.url, '_blank');
      if (newWindow) {
        newWindow.opener = null; // Enhanced security
      }
    }
  };

  return (
    <>
      <label htmlFor="file-upload">
        <Input
          id="file-upload"
          type="file"
          disabled={props.disabled}
          inputProps={{
            accept: _.join(
              [AcceptMimeType('image'), AcceptMimeType('pdf')],
              ',',
            ),
            multiple: true,
          }}
          sx={{ display: 'none' }}
          onChange={props.handleFileChangeFn}
        />

        <Button
          variant="outlined"
          component="span"
          disabled={props.disabled}
          sx={{ mr: 4 }}
          startIcon={<CloudUploadIcon />}>
          File Upload
        </Button>
      </label>

      {/* TODO: Below logic needs improvement */}
      {props.mode === 'create' &&
        (props.localFiles.length > 0 ? (
          <>
            <List>
              {_.map(props.localFiles, (file, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={<Typography>{file.name}</Typography>}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => triggerConfirmDialog(file)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No files uploaded
          </Typography>
        ))}

      {(props.mode === 'edit' || props.mode === 'delete') &&
        (props.apiFiles.length > 0 ? (
          <>
            <List>
              {_.map(props.apiFiles, (file, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={
                      <Typography
                        color={
                          props.disabled ? 'textSecondary' : 'textPrimary'
                        }>
                        {file.name}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Stack direction="row" spacing={1}>
                      <Tooltip
                        title="Open File"
                        placement="left"
                        disableHoverListener={props.disabled}>
                        <span>
                          <IconButton
                            edge="end"
                            disabled={props.disabled}
                            onClick={() => handleOpenFile(file)}>
                            <OpenInNewIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip
                        title="Delete File"
                        disableHoverListener={props.disabled}>
                        <span>
                          <IconButton
                            edge="end"
                            color="error"
                            disabled={props.disabled}
                            onClick={() => triggerConfirmDialog(file)}>
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Stack>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No files uploaded
          </Typography>
        ))}

      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          title="Confirm Delete"
          content="Are you sure you want to delete the file ?"
          onClose={onConfirmDialogClose}
          onConfirm={onConfirmDialogConfirm}
        />
      )}
    </>
  );
};

export default React.memo(FileUpload);
