import axios from 'axios';

axios.defaults.headers.common['App-Version'] =
  `${process.env.REACT_APP_VERSION}`;
axios.defaults.baseURL = process.env.REACT_APP_SERVER_BASE_URL;
axios.defaults.withCredentials = true;

// axios.interceptors.response.use(
//   response => response,
//   error => {
//     if (
//       !navigator.onLine ||
//       (error.response && error.response.status === 401)
//     ) {
//       // Redirect to login page if unauthorized
//       if (typeof window !== 'undefined' && error.config.url !== '/login') {
//         window.location.href = '/login';
//       }
//     }
//     return Promise.reject(error);
//   },
// );

export default axios;
