import { Zoom } from '@mui/material';

import { SlideRightTransition } from './motionTransitions';

const components = {
  components: {
    MuiSnackbar: {
      defaultProps: {
        TransitionComponent: SlideRightTransition,
      },
    },
    MuiDialog: {
      defaultProps: {
        TransitionComponent: Zoom,
      },
    },
  },
};

export default components;
