import React, { useState } from 'react';

import { Box, Button, FormControl, Grid, TextField } from '@mui/material';

import { InventoryModel } from 'apis/InventoryApi';
import { FormModeType } from './Inventories';
import { useLoader } from 'contexts/LoaderContext';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { DateFormatString } from 'constants/DateTimeFormat';

interface InventoryFormType {
  mode: FormModeType | undefined;
  initFormState: Partial<InventoryModel>;
  callbackFn: (inventory: Partial<InventoryModel>) => void;
  handleCloseFn: () => void;
}

const InventoryForm = (props: InventoryFormType) => {
  const [formState, setFormState] = useState(props.initFormState);
  const { loading } = useLoader();
  const disabled = props.mode === 'delete';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (name: string) => (newDate: Moment | null) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: newDate?.unix(),
    }));
  };

  const handleChangeUpperCase = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value.toUpperCase(),
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.callbackFn(formState as Partial<InventoryModel>);
  };

  const btnName = () => {
    switch (props.mode) {
      case 'create':
        return 'Add';
      case 'edit':
        return 'Save';
      case 'delete':
        return 'Delete';
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              type="text"
              name="name"
              autoFocus
              value={formState.name}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Code"
              type="text"
              name="code"
              value={formState.code}
              onChange={handleChangeUpperCase}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 10,
                style: {
                  textTransform: 'uppercase',
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Category"
              type="text"
              name="category"
              autoFocus
              value={formState.category}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Quantity"
              type="number"
              name="quantity"
              value={formState.quantity}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Price"
              type="number"
              name="price"
              value={formState.price}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Limit"
              type="number"
              name="limit"
              value={formState.limit}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Expiry"
              name="expiry"
              value={moment.unix(formState.expiry as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('expiry')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} pb={1}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Notes"
              name="notes"
              value={formState.notes}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} textAlign={'right'} pt={1}>
          <Button onClick={props.handleCloseFn} variant="text" sx={{ mr: 4 }}>
            {'Close'}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ px: 6, borderRadius: 2 }}
            loading={loading}
            loadingIndicator={'Loading...'}>
            {btnName()}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(InventoryForm);
