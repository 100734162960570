import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  createTimeSheet,
  deleteTimeSheet,
  TimeSheetModel,
  getTimeSheets,
  updateTimeSheet,
} from 'apis/TimeSheetApi';
import { useLoader } from 'contexts/LoaderContext';
import TimeSheetForm from './TimeSheetForm';
import {
  listAccounts,
  listCustomers,
  listEmployees,
  listInventories,
  listProjects,
  listSuppliers,
  listVehicles,
  OptionsType,
} from 'apis/ListApi';
import axios from 'apis/AxiosConfig';
import { pageSizeOptions } from 'constants/PaginationOptions';
import {
  TimeSheetFormStatusOptions,
  TimeSheetFormTypeOptions,
} from 'constants/DropDownOptions';
import {
  CurrentDate,
  DateFormat,
  ParseEpochDateString,
} from 'utils/EpochConverter';
import { useAuth } from 'contexts/AuthContext';

export type FormModeType = 'create' | 'edit' | 'delete';

const TimeSheets = () => {
  const { branch, employee } = useAuth();
  const { loading, showLoader, hideLoader } = useLoader();

  const [selectedTimeSheetID, setSelectedTimeSheetID] = useState<GridRowId>();
  const [timeSheetsData, setTimeSheetsData] = useState<TimeSheetModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const [accountsList, setAccountsList] = useState<OptionsType[]>([]);
  const [employeesList, setEmployeesList] = useState<OptionsType[]>([]);
  const [projectsList, setProjectsList] = useState<OptionsType[]>([]);
  const [customersList, setCustomersList] = useState<OptionsType[]>([]);
  const [suppliersList, setSuppliersList] = useState<OptionsType[]>([]);
  const [vehiclesList, setVehiclesList] = useState<OptionsType[]>([]);
  const [inventoriesList, setInventoriesList] = useState<OptionsType[]>([]);

  const fetchData = () => {
    showLoader();
    getTimeSheets({
      branchId: branch?.id,
      page: page + 1,
      pageSize: pageSize,
      sort: sortColumn + ':' + sortOrder,
      filter: filterField + ':' + filterOperator + ':' + filterValue,
    })
      .then(response => {
        setTimeSheetsData(response.rows);
        setRowCount(response.total);
      })
      .catch(error => console.error('Error Fetching Time Sheets: ', error))
      .finally(() => hideLoader());
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  useEffect(() => {
    axios
      .all([
        listAccounts({ branchId: branch?.id }),
        listEmployees({ branchId: branch?.id }),
        listProjects({ branchId: branch?.id }),
        listCustomers({ branchId: branch?.id }),
        listSuppliers({ branchId: branch?.id }),
        listVehicles({ branchId: branch?.id }),
        listInventories({ branchId: branch?.id }),
      ])
      .then(
        axios.spread(
          (
            accountsList,
            employeesList,
            projectsList,
            customersList,
            suppliersList,
            vehiclesList,
            inventoriesList,
          ) => {
            setAccountsList(accountsList);
            setEmployeesList(employeesList);
            setProjectsList(projectsList);
            setCustomersList(customersList);
            setSuppliersList(suppliersList);
            setVehiclesList(vehiclesList);
            setInventoriesList(inventoriesList);
          },
        ),
      );
  }, []);

  const handleAddTimeSheet = (newTimeSheet: Partial<TimeSheetModel>) => {
    showLoader();
    createTimeSheet(newTimeSheet as Omit<TimeSheetModel, 'id'>)
      .then(response => {
        setTimeSheetsData(prevTimeSheets => [...prevTimeSheets, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding time sheet: ', error))
      .finally(() => hideLoader());
  };

  const handleEditTimeSheet = (editedTimeSheet: Partial<TimeSheetModel>) => {
    showLoader();
    if (selectedTimeSheetID) {
      updateTimeSheet(selectedTimeSheetID, editedTimeSheet)
        .then(response => {
          setTimeSheetsData(
            _.chain(timeSheetsData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(timeSheets => {
                const index = _.findIndex(
                  timeSheets,
                  trans => trans.id === selectedTimeSheetID,
                );
                if (index !== -1) timeSheets[index] = response;
                return timeSheets;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing time sheet: ', error))
        .finally(() => hideLoader());
    }
  };

  const handleDeleteTimeSheet = () => {
    if (selectedTimeSheetID) {
      showLoader();
      deleteTimeSheet(selectedTimeSheetID)
        .then(() => {
          setTimeSheetsData(
            _.reject(timeSheetsData, {
              id: selectedTimeSheetID,
            }) as TimeSheetModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting time sheet: ', error))
        .finally(() => hideLoader());
    }
  };

  const openTimeSheetForm = () => {
    let title: string;
    let currentTimeSheet: Partial<TimeSheetModel> = {
      branchId: branch?.id,
      employeeId: employee?.id,
      verifierId: 0,
      accountId: 0,
      projectId: 0,
      customerId: 0,
      supplierId: 0,
      vehicleId: 0,
      inventoryId: 0,
      timeSheetType: '',
      status: '',
      day: CurrentDate(),
      hour0: 0,
      hour1: 0,
      hour2: 0,
      hour3: 0,
      hour4: 0,
      hour5: 0,
      hour6: 0,
      hour7: 0,
      hour8: 0,
      hour9: 0,
      hour10: 0,
      hour11: 0,
      hour12: 0,
      hour13: 0,
      hour14: 0,
      hour15: 0,
      hour16: 0,
      hour17: 0,
      hour18: 0,
      hour19: 0,
      hour20: 0,
      hour21: 0,
      hour22: 0,
      hour23: 0,
      notes: '',
    };
    let callback: (timeSheet: Partial<TimeSheetModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Time Sheet';
        callback = handleAddTimeSheet;
        break;
      case 'edit':
        title = 'Edit Time Sheet';
        callback = handleEditTimeSheet;
        currentTimeSheet = _.chain(timeSheetsData)
          .find(timeSheet => timeSheet.id === selectedTimeSheetID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Time Sheet';
        callback = handleDeleteTimeSheet;
        currentTimeSheet = _.chain(timeSheetsData)
          .find(timeSheet => timeSheet.id === selectedTimeSheetID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Time Sheet';
        callback = handleAddTimeSheet;
    }
    return (
      <Dialog
        open={openForm}
        fullScreen={fullScreen}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: fullScreen ? 0 : 4 } }}
        sx={{ backdropFilter: 'blur(2px)' }}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <TimeSheetForm
            mode={formMode}
            initFormState={currentTimeSheet}
            accountsList={accountsList}
            employeesList={employeesList}
            projectsList={projectsList}
            customersList={customersList}
            suppliersList={suppliersList}
            vehiclesList={vehiclesList}
            inventoriesList={inventoriesList}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'timeSheetType',
      headerName: 'TYPE',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(TimeSheetFormTypeOptions, record => {
        return record.value;
      }),
      getOptionLabel: value => {
        const result = TimeSheetFormTypeOptions.find(record => {
          return record.value == (value as string);
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(TimeSheetFormStatusOptions, record => {
        return record.value;
      }),
      getOptionLabel: value => {
        const result = TimeSheetFormStatusOptions.find(record => {
          return record.value == (value as string);
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'day',
      headerName: 'DAY',
      flex: 0.6,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'employeeId',
      headerName: 'EMPLOYEE',
      flex: 0.6,
      type: 'singleSelect',
      valueOptions: _.map(employeesList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = employeesList.find(record => {
          return record.id == (value as string);
        });
        return result ? result.name : '...';
      },
    },
    {
      field: 'verifierId',
      headerName: 'VERIFIER',
      flex: 0.6,
      type: 'singleSelect',
      valueOptions: _.map(employeesList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = employeesList.find(record => {
          return record.id == (value as string);
        });
        return result ? result.name : '...';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ACTIONS',
      flex: 0.4,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              setSelectedTimeSheetID(id);
              handleOpen('edit');
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            color="inherit"
            onClick={() => {
              setSelectedTimeSheetID(id);
              handleOpen('delete');
            }}
          />,
        ];
      },
    },
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ pb: 1 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: 2 }}
          onClick={() => handleOpen('create')}>
          <AddIcon /> Add
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>TimeSheets</title>
      </Helmet>

      <Paper sx={{ height: '50%', minHeight: 400, borderRadius: 4 }}>
        <DataGrid
          loading={loading}
          rows={timeSheetsData}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                if (['day'].includes(filterModel.field)) {
                  setFilterValue(ParseEpochDateString(filterModel.value));
                } else {
                  setFilterValue(filterModel.value);
                }
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableRowSelectionOnClick
          sx={{ px: 2, pt: 2, borderRadius: 4 }}
        />
      </Paper>

      {openForm && openTimeSheetForm()}
    </>
  );
};

export default React.memo(TimeSheets);
