const CurrencyFormatter = Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumFractionDigits: 2,
});

const format = (value: number) => {
  return CurrencyFormatter.format(value).replace(/^(\D+)/, '$1 ');
};

export default format;
