import React from 'react';
import { Alert, AlertTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface FallBackType {
  title: string;
  message: string;
}

const FallBack = (props: FallBackType) => {
  const navigate = useNavigate();

  return (
    <Alert
      variant="filled"
      severity="error"
      action={
        <Button color="inherit" size="small" onClick={() => navigate('/')}>
          Go Home
        </Button>
      }
      sx={{ m: 1 }}>
      <AlertTitle> {props.title} </AlertTitle>
      {props.message}
    </Alert>
  );
};

export default React.memo(FallBack);
