import React, { useState } from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment';

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { TimeSheetModel } from 'apis/TimeSheetApi';
import { useLoader } from 'contexts/LoaderContext';
import { OptionsType } from 'apis/ListApi';
import { FormModeType } from './TimeSheets';
import {
  TimeSheetFormHourOptions,
  TimeSheetFormStatusOptions,
  TimeSheetFormTypeOptions,
} from 'constants/DropDownOptions';
import { DatePicker } from '@mui/x-date-pickers';
import { DateFormatString } from 'constants/DateTimeFormat';

interface TimeSheetFormType {
  mode: FormModeType | undefined;
  initFormState: Partial<TimeSheetModel>;
  accountsList: OptionsType[];
  employeesList: OptionsType[];
  projectsList: OptionsType[];
  customersList: OptionsType[];
  suppliersList: OptionsType[];
  vehiclesList: OptionsType[];
  inventoriesList: OptionsType[];
  callbackFn: (timeSheet: Partial<TimeSheetModel>) => void;
  handleCloseFn: () => void;
}

const TimeSheetForm = (props: TimeSheetFormType) => {
  const [formState, setFormState] = useState(props.initFormState);
  const { loading } = useLoader();
  const disabled = props.mode === 'delete';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (name: string) => (newDate: Moment | null) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: newDate?.unix(),
    }));
  };

  const handleAutocompleteChange = (name: string, value: string) => {
    setFormState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.callbackFn(formState as Partial<TimeSheetModel>);
  };

  const btnName = () => {
    switch (props.mode) {
      case 'create':
        return 'Add';
      case 'edit':
        return 'Save';
      case 'delete':
        return 'Delete';
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.employeesList.find(entry => {
                return entry.id == formState?.employeeId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Employee" />}
              options={
                !props.employeesList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.employeesList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('employeeId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.employeesList.find(entry => {
                return entry.id == formState?.verifierId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Verifier" />}
              options={
                !props.employeesList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.employeesList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('verifierId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.accountsList.find(entry => {
                return entry.id == formState?.accountId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Account" />}
              options={
                !props.accountsList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.accountsList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('accountId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.projectsList.find(entry => {
                return entry.id == formState?.projectId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Project" />}
              options={
                !props.projectsList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.projectsList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('projectId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.customersList.find(entry => {
                return entry.id == formState?.customerId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Customer" />}
              options={
                !props.customersList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.customersList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('customerId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.suppliersList.find(entry => {
                return entry.id == formState?.supplierId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Supplier" />}
              options={
                !props.suppliersList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.suppliersList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('supplierId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.vehiclesList.find(entry => {
                return entry.id == formState?.vehicleId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Vehicle" />}
              options={
                !props.vehiclesList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.vehiclesList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('vehicleId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.inventoriesList.find(entry => {
                return entry.id == formState?.inventoryId?.toString() || '';
              })}
              renderInput={params => (
                <TextField {...params} label="Inventory" />
              )}
              options={
                !props.inventoriesList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.inventoriesList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('inventoryId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="type-select-label">
              {disabled ? 'Type' : 'Type *'}
            </InputLabel>
            <Select
              label="Type"
              labelId="timeSheetType-select-label"
              id="timeSheetType-select"
              name="timeSheetType"
              value={formState.timeSheetType}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormTypeOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="status-select-label">
              {disabled ? 'Status' : 'Status *'}
            </InputLabel>
            <Select
              label="Status"
              labelId="status-select-label"
              id="status-select"
              name="status"
              value={formState.status}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormStatusOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Day"
              name="day"
              value={moment.unix(formState.day as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('day')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour0-select-label">
              {disabled ? '12 AM' : '12 AM *'}
            </InputLabel>
            <Select
              label="12 AM"
              labelId="hour0-select-label"
              id="hour0-select"
              name="hour0"
              value={formState.hour0?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour1-select-label">
              {disabled ? '1 AM' : '1 AM *'}
            </InputLabel>
            <Select
              label="1 AM"
              labelId="hour1-select-label"
              id="hour1-select"
              name="hour1"
              value={formState.hour1?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour2-select-label">
              {disabled ? '2 AM' : '2 AM *'}
            </InputLabel>
            <Select
              label="2 AM"
              labelId="hour2-select-label"
              id="hour2-select"
              name="hour2"
              value={formState.hour2?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour3-select-label">
              {disabled ? '3 AM' : '3 AM *'}
            </InputLabel>
            <Select
              label="3 AM"
              labelId="hour3-select-label"
              id="hour3-select"
              name="hour3"
              value={formState.hour3?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour4-select-label">
              {disabled ? '4 AM' : '4 AM *'}
            </InputLabel>
            <Select
              label="4 AM"
              labelId="hour4-select-label"
              id="hour4-select"
              name="hour4"
              value={formState.hour4?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour5-select-label">
              {disabled ? '5 AM' : '5 AM *'}
            </InputLabel>
            <Select
              label="5 AM"
              labelId="hour5-select-label"
              id="hour5-select"
              name="hour5"
              value={formState.hour5?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour6-select-label">
              {disabled ? '6 AM' : '6 AM *'}
            </InputLabel>
            <Select
              label="6 AM"
              labelId="hour6-select-label"
              id="hour6-select"
              name="hour6"
              value={formState.hour6?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour7-select-label">
              {disabled ? '7 AM' : '7 AM *'}
            </InputLabel>
            <Select
              label="7 AM"
              labelId="hour7-select-label"
              id="hour7-select"
              name="hour7"
              value={formState.hour7?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour8-select-label">
              {disabled ? '8 AM' : '8 AM *'}
            </InputLabel>
            <Select
              label="8 AM"
              labelId="hour8-select-label"
              id="hour8-select"
              name="hour8"
              value={formState.hour8?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour9-select-label">
              {disabled ? '9 AM' : '9 AM *'}
            </InputLabel>
            <Select
              label="9 AM"
              labelId="hour9-select-label"
              id="hour9-select"
              name="hour9"
              value={formState.hour9?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour10-select-label">
              {disabled ? '10 AM' : '10 AM *'}
            </InputLabel>
            <Select
              label="10 AM"
              labelId="hour10-select-label"
              id="hour10-select"
              name="hour10"
              value={formState.hour10?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour11-select-label">
              {disabled ? '11 AM' : '11 AM *'}
            </InputLabel>
            <Select
              label="11 AM"
              labelId="hour11-select-label"
              id="hour11-select"
              name="hour11"
              value={formState.hour11?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour12-select-label">
              {disabled ? '12 PM' : '12 PM *'}
            </InputLabel>
            <Select
              label="12 AM"
              labelId="hour12-select-label"
              id="hour12-select"
              name="hour12"
              value={formState.hour12?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour13-select-label">
              {disabled ? '1 PM' : '1 PM *'}
            </InputLabel>
            <Select
              label="1 PM"
              labelId="hour13-select-label"
              id="hour13-select"
              name="hour13"
              value={formState.hour13?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour14-select-label">
              {disabled ? '2 PM' : '2 PM *'}
            </InputLabel>
            <Select
              label="2 PM"
              labelId="hour14-select-label"
              id="hour14-select"
              name="hour14"
              value={formState.hour14?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour15-select-label">
              {disabled ? '3 PM' : '3 PM *'}
            </InputLabel>
            <Select
              label="3 PM"
              labelId="hour15-select-label"
              id="hour15-select"
              name="hour15"
              value={formState.hour15?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour16-select-label">
              {disabled ? '4 PM' : '4 PM *'}
            </InputLabel>
            <Select
              label="4 PM"
              labelId="hour16-select-label"
              id="hour16-select"
              name="hour16"
              value={formState.hour16?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour17-select-label">
              {disabled ? '5 PM' : '5 PM *'}
            </InputLabel>
            <Select
              label="5 PM"
              labelId="hour17-select-label"
              id="hour17-select"
              name="hour17"
              value={formState.hour17?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour18-select-label">
              {disabled ? '6 PM' : '6 PM *'}
            </InputLabel>
            <Select
              label="6 PM"
              labelId="hour18-select-label"
              id="hour18-select"
              name="hour18"
              value={formState.hour18?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour19-select-label">
              {disabled ? '7 PM' : '7 PM *'}
            </InputLabel>
            <Select
              label="7 PM"
              labelId="hour19-select-label"
              id="hour19-select"
              name="hour19"
              value={formState.hour19?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour20-select-label">
              {disabled ? '8 PM' : '8 PM *'}
            </InputLabel>
            <Select
              label="8 PM"
              labelId="hour20-select-label"
              id="hour20-select"
              name="hour20"
              value={formState.hour20?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour21-select-label">
              {disabled ? '9 PM' : '9 PM *'}
            </InputLabel>
            <Select
              label="9 PM"
              labelId="hour21-select-label"
              id="hour21-select"
              name="hour21"
              value={formState.hour21?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour22-select-label">
              {disabled ? '10 PM' : '10 PM *'}
            </InputLabel>
            <Select
              label="10 PM"
              labelId="hour22-select-label"
              id="hour22-select"
              name="hour22"
              value={formState.hour22?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="hour23-select-label">
              {disabled ? '11 PM' : '11 PM *'}
            </InputLabel>
            <Select
              label="11 PM"
              labelId="hour23-select-label"
              id="hour23-select"
              name="hour23"
              value={formState.hour23?.toString() || '0'}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(TimeSheetFormHourOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} pb={1}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Notes"
              name="notes"
              value={formState.notes}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} textAlign={'right'} pt={1}>
          <Button onClick={props.handleCloseFn} variant="text" sx={{ mr: 4 }}>
            {'Close'}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ px: 6, borderRadius: 2 }}
            loading={loading}
            loadingIndicator={'Loading...'}>
            {btnName()}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(TimeSheetForm);
