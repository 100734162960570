import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  createInventory,
  deleteInventory,
  InventoryModel,
  getInventories,
  updateInventory,
} from 'apis/InventoryApi';
import { useLoader } from 'contexts/LoaderContext';
import InventoryForm from './InventoryForm';
import { pageSizeOptions } from 'constants/PaginationOptions';
import { useAuth } from 'contexts/AuthContext';
import { CurrentDate, DateFormat } from 'utils/EpochConverter';
import format from 'utils/CurrencyFormatter';

export type FormModeType = 'create' | 'edit' | 'delete';

const Inventories = () => {
  const { branch } = useAuth();
  const { loading, showLoader, hideLoader } = useLoader();

  const [selectedInventoryID, setSelectedInventoryID] = useState<GridRowId>();
  const [inventoriesData, setInventoriesData] = useState<InventoryModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const fetchData = () => {
    showLoader();
    getInventories({
      branchId: branch?.id,
      page: page + 1,
      pageSize: pageSize,
      sort: sortColumn + ':' + sortOrder,
      filter: filterField + ':' + filterOperator + ':' + filterValue,
    })
      .then(response => {
        setInventoriesData(response.rows);
        setRowCount(response.total);
      })
      .catch(error => console.error('Error Fetching Inventories: ', error))
      .finally(() => hideLoader());
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  const handleAddInventory = (newInventory: Partial<InventoryModel>) => {
    showLoader();
    createInventory(newInventory as Omit<InventoryModel, 'id'>)
      .then(response => {
        setInventoriesData(prevInventories => [...prevInventories, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding inventory: ', error))
      .finally(() => hideLoader());
  };

  const handleEditInventory = (editedInventory: Partial<InventoryModel>) => {
    showLoader();
    if (selectedInventoryID) {
      updateInventory(selectedInventoryID, editedInventory)
        .then(response => {
          setInventoriesData(
            _.chain(inventoriesData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(inventories => {
                const index = _.findIndex(
                  inventories,
                  acc => acc.id === selectedInventoryID,
                );
                if (index !== -1) inventories[index] = response;
                return inventories;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing inventory: ', error))
        .finally(() => hideLoader());
    }
  };

  const handleDeleteInventory = () => {
    if (selectedInventoryID) {
      showLoader();
      deleteInventory(selectedInventoryID)
        .then(() => {
          setInventoriesData(
            _.reject(inventoriesData, {
              id: selectedInventoryID,
            }) as InventoryModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting inventory: ', error))
        .finally(() => hideLoader());
    }
  };

  const openInventoryForm = () => {
    let title: string;
    let currentInventory: Partial<InventoryModel> = {
      branchId: branch?.id,
      name: '',
      code: '',
      category: '',
      quantity: 0,
      price: 0,
      limit: 0,
      expiry: CurrentDate(),
      notes: '',
    };
    let callback: (inventory: Partial<InventoryModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Inventory';
        callback = handleAddInventory;
        break;
      case 'edit':
        title = 'Edit Inventory';
        callback = handleEditInventory;
        currentInventory = _.chain(inventoriesData)
          .find(transaction => transaction.id === selectedInventoryID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Inventory';
        callback = handleDeleteInventory;
        currentInventory = _.chain(inventoriesData)
          .find(transaction => transaction.id === selectedInventoryID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Inventory';
        callback = handleAddInventory;
    }
    return (
      <Dialog
        open={openForm}
        fullScreen={fullScreen}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: fullScreen ? 0 : 4 } }}
        sx={{ backdropFilter: 'blur(2px)' }}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <InventoryForm
            mode={formMode}
            initFormState={currentInventory}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'code',
      headerName: 'CODE',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'quantity',
      headerName: 'QUANTITY',
      flex: 0.6,
      type: 'number',
    },
    {
      field: 'price',
      headerName: 'PRICE',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && format(value),
    },
    {
      field: 'limit',
      headerName: 'LIMIT',
      flex: 0.6,
      type: 'number',
    },
    {
      field: 'expiry',
      headerName: 'EXPIRY',
      flex: 0.6,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ACTIONS',
      flex: 0.4,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              setSelectedInventoryID(id);
              handleOpen('edit');
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            color="inherit"
            onClick={() => {
              setSelectedInventoryID(id);
              handleOpen('delete');
            }}
          />,
        ];
      },
    },
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ pb: 1 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: 2 }}
          onClick={() => handleOpen('create')}>
          <AddIcon /> Add
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>Inventories</title>
      </Helmet>

      <Paper sx={{ height: '50%', minHeight: 400, borderRadius: 4 }}>
        <DataGrid
          loading={loading}
          rows={inventoriesData}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableRowSelectionOnClick
          sx={{ px: 2, pt: 2, borderRadius: 4 }}
        />
      </Paper>

      {openForm && openInventoryForm()}
    </>
  );
};

export default React.memo(Inventories);
