import moment from 'moment';
import {
  DateFormatString,
  DateTimeFormatString,
  TimeFormatString,
} from 'constants/DateTimeFormat';

export const CurrentDateTime = () => {
  return moment().startOf('minute').unix();
};
export const CurrentDate = () => {
  return moment().startOf('day').unix();
};
export const DateTimeFormat = (value: number) => {
  return moment.unix(value).format(DateTimeFormatString);
};
export const DateFormat = (value: number) => {
  return moment.unix(value).format(DateFormatString);
};
export const TimeFormat = (value: number) => {
  return moment.unix(value).format(TimeFormatString);
};
export const ParseMoment = (value: string) => {
  return moment(value);
};
export const ParseEpochDateTime = (value: string) => {
  return ParseMoment(value).startOf('minute').unix();
};
export const ParseEpochDateTimeString = (value: string) => {
  return ParseMoment(value).startOf('minute').unix() as unknown as string;
};
export const ParseEpochDate = (value: string) => {
  return ParseMoment(value).startOf('day').unix();
};
export const ParseEpochDateString = (value: string) => {
  return ParseMoment(value).startOf('day').unix() as unknown as string;
};
