import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

import { EmployeeModel } from 'apis/EmployeeApi';
import { BranchModel } from 'apis/BranchApi';
import axios from 'apis/AxiosConfig';

export interface LoginReqType {
  email: string;
  password: string;
  branchCode: string;
  deviceId: string;
}

interface LoginResType {
  employee: EmployeeModel;
  branch: BranchModel;
}

interface AuthContextType {
  isAuthenticated: boolean;
  authLoading: boolean;
  authCheck: () => Promise<boolean>;
  login: (loginReq: LoginReqType) => Promise<boolean>;
  logout: () => Promise<boolean>;
  branch: BranchModel | undefined;
  employee: EmployeeModel | undefined;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [branch, setBranch] = useState<BranchModel | undefined>();
  const [employee, setEmployee] = useState<EmployeeModel | undefined>();

  const resetAuth = (): boolean => {
    setIsAuthenticated(false);
    setBranch(undefined);
    setEmployee(undefined);
    return false;
  };

  useEffect(() => {
    if (!isAuthenticated) {
      authCheck();
    }
  }, []);

  const authCheck = async (): Promise<boolean> => {
    setAuthLoading(true);
    try {
      const response = await axios.post<LoginResType>('/api/auth/check');
      if (response.status == 200) {
        setIsAuthenticated(true);
        setBranch(response.data.branch);
        setEmployee(response.data.employee);
        return true;
      }
      return resetAuth();
    } catch (error) {
      return resetAuth();
    } finally {
      setAuthLoading(false);
    }
  };

  const login = async (loginReq: LoginReqType): Promise<boolean> => {
    setAuthLoading(true);
    try {
      const response = await axios.post<LoginResType>(
        '/api/auth/login',
        loginReq,
      );
      if (response.status == 200) {
        setIsAuthenticated(true);
        setBranch(response.data.branch);
        setEmployee(response.data.employee);
        return true;
      }
      return resetAuth();
    } catch (error) {
      return resetAuth();
    } finally {
      setAuthLoading(false);
    }
  };

  const logout = async (): Promise<boolean> => {
    try {
      const response = await axios.post<LoginResType>('/api/auth/logout');
      if (response.status == 200) {
        resetAuth();
        return true;
      }
      return resetAuth();
    } catch (error) {
      return resetAuth();
    } finally {
      setAuthLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authLoading,
        authCheck,
        login,
        logout,
        branch,
        employee,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
