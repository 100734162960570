import axios from 'apis/AxiosConfig';

interface FileReqType {
  'files[]': File[];
}

export interface FileResType {
  id: number;
  signedId: string;
  name: string;
  url: string;
}

export const updateEmployeeFile = async (
  empId: number,
  files: FileReqType,
): Promise<FileResType[]> => {
  const response = await axios.post<FileResType[]>(
    `/employees/${empId}/files`,
    files,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const getEmployeeFiles = async (id: number): Promise<FileResType[]> => {
  const response = await axios.get<FileResType[]>(`/employees/${id}/files`);
  return response.data;
};

export const deleteEmployeeFile = async (
  id: number,
  signedId: string,
): Promise<void> => {
  await axios.delete(`/employees/${id}/files/${signedId}`);
};
