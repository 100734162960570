import React, { useState } from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment';

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { ProjectModel } from 'apis/ProjectApi';
import { FormModeType } from './Projects';
import { useLoader } from 'contexts/LoaderContext';
import { ProjectFormStatusOptions } from 'constants/DropDownOptions';
import { DatePicker } from '@mui/x-date-pickers';
import { DateFormatString } from 'constants/DateTimeFormat';
import { OptionsType } from 'apis/ListApi';

interface ProjectFormType {
  mode: FormModeType | undefined;
  initFormState: Partial<ProjectModel>;
  customersList: OptionsType[];
  callbackFn: (project: Partial<ProjectModel>) => void;
  handleCloseFn: () => void;
}

const ProjectForm = (props: ProjectFormType) => {
  const [formState, setFormState] = useState(props.initFormState);
  const { loading } = useLoader();
  const disabled = props.mode === 'delete';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAutocompleteChange = (name: string, value: string) => {
    setFormState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleDateChange = (name: string) => (newDate: Moment | null) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: newDate?.unix(),
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.callbackFn(formState as Partial<ProjectModel>);
  };

  const btnName = () => {
    switch (props.mode) {
      case 'create':
        return 'Add';
      case 'edit':
        return 'Save';
      case 'delete':
        return 'Delete';
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              type="text"
              name="name"
              autoFocus
              value={formState.name}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              autoFocus
              getOptionLabel={option => option.name}
              value={props.customersList.find(entry => {
                return entry.id == formState?.customerId?.toString() || '';
              })}
              renderInput={params => <TextField {...params} label="Customer" />}
              options={
                !props.customersList
                  ? [{ id: '0', name: 'Loading...' }]
                  : props.customersList
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                if (newValue) {
                  handleAutocompleteChange('customerId', newValue.id);
                }
              }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Location"
              type="text"
              name="location"
              value={formState.location}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal" disabled={disabled}>
            <InputLabel id="status-select-label">
              {disabled ? 'Status' : 'Status *'}
            </InputLabel>
            <Select
              label="Status"
              labelId="status-select-label"
              id="status-select"
              name="status"
              value={formState.status}
              onChange={handleSelectChange}
              required={!disabled}>
              {_.map(ProjectFormStatusOptions, option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Start Date"
              name="startDate"
              value={moment.unix(formState.startDate as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('startDate')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="End Date"
              name="endDate"
              value={moment.unix(formState.endDate as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('endDate')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Total Budget"
              type="number"
              name="totalBudget"
              value={formState.totalBudget}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Total Expense"
              type="number"
              name="totalExpense"
              value={formState.totalExpense}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>₹ </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} pb={1}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Notes"
              name="notes"
              value={formState.notes}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} textAlign={'right'} pt={1}>
          <Button onClick={props.handleCloseFn} variant="text" sx={{ mr: 4 }}>
            {'Close'}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ px: 6, borderRadius: 2 }}
            loading={loading}
            loadingIndicator={'Loading...'}>
            {btnName()}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(ProjectForm);
