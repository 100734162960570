import { ThemeOptions } from '@mui/material/styles';

import typography from './typography';

// TODO: needs proper theme update - works partially !!
const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
  },
  typography: typography,
};

export default darkThemeOptions;
