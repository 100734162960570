import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  createTransaction,
  deleteTransaction,
  TransactionModel,
  getTransactions,
  updateTransaction,
} from 'apis/TransactionApi';
import { useLoader } from 'contexts/LoaderContext';
import TransactionForm from './TransactionForm';
import format from 'utils/CurrencyFormatter';
import {
  listAccounts,
  listCustomers,
  listEmployees,
  listInventories,
  listProjects,
  listSuppliers,
  listVehicles,
  OptionsType,
} from 'apis/ListApi';
import axios from 'apis/AxiosConfig';
import { pageSizeOptions } from 'constants/PaginationOptions';
import {
  TransactionFormCategoryOptions,
  TransactionFormStatusOptions,
  TransactionFormTypeOptions,
} from 'constants/DropDownOptions';
import { useAuth } from 'contexts/AuthContext';

export type FormModeType = 'create' | 'edit' | 'delete';

const Transactions = () => {
  const { branch, employee } = useAuth();
  const { loading, showLoader, hideLoader } = useLoader();

  const [selectedTransactionID, setSelectedTransactionID] =
    useState<GridRowId>();
  const [transactionsData, setTransactionsData] = useState<TransactionModel[]>(
    [],
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const [accountsList, setAccountsList] = useState<OptionsType[]>([]);
  const [employeesList, setEmployeesList] = useState<OptionsType[]>([]);
  const [projectsList, setProjectsList] = useState<OptionsType[]>([]);
  const [customersList, setCustomersList] = useState<OptionsType[]>([]);
  const [suppliersList, setSuppliersList] = useState<OptionsType[]>([]);
  const [vehiclesList, setVehiclesList] = useState<OptionsType[]>([]);
  const [inventoriesList, setInventoriesList] = useState<OptionsType[]>([]);

  const fetchData = () => {
    showLoader();
    getTransactions({
      branchId: branch?.id,
      page: page + 1,
      pageSize: pageSize,
      sort: sortColumn + ':' + sortOrder,
      filter: filterField + ':' + filterOperator + ':' + filterValue,
    })
      .then(response => {
        setTransactionsData(response.rows);
        setRowCount(response.total);
      })
      .catch(error => console.error('Error Fetching Projects: ', error))
      .finally(() => hideLoader());
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  useEffect(() => {
    axios
      .all([
        listAccounts({ branchId: branch?.id }),
        listEmployees({ branchId: branch?.id }),
        listProjects({ branchId: branch?.id }),
        listCustomers({ branchId: branch?.id }),
        listSuppliers({ branchId: branch?.id }),
        listVehicles({ branchId: branch?.id }),
        listInventories({ branchId: branch?.id }),
      ])
      .then(
        axios.spread(
          (
            accountsList,
            employeesList,
            projectsList,
            customersList,
            suppliersList,
            vehiclesList,
            inventoriesList,
          ) => {
            setAccountsList(accountsList);
            setEmployeesList(employeesList);
            setProjectsList(projectsList);
            setCustomersList(customersList);
            setSuppliersList(suppliersList);
            setVehiclesList(vehiclesList);
            setInventoriesList(inventoriesList);
          },
        ),
      );
  }, []);

  const handleAddTransaction = (newTransaction: Partial<TransactionModel>) => {
    showLoader();
    createTransaction(newTransaction as Omit<TransactionModel, 'id'>)
      .then(response => {
        setTransactionsData(prevTransactions => [
          ...prevTransactions,
          response,
        ]);
        handleClose();
      })
      .catch(error => console.error('Error adding transaction: ', error))
      .finally(() => hideLoader());
  };

  const handleEditTransaction = (
    editedTransaction: Partial<TransactionModel>,
  ) => {
    showLoader();
    if (selectedTransactionID) {
      updateTransaction(selectedTransactionID, editedTransaction)
        .then(response => {
          setTransactionsData(
            _.chain(transactionsData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(transactions => {
                const index = _.findIndex(
                  transactions,
                  trans => trans.id === selectedTransactionID,
                );
                if (index !== -1) transactions[index] = response;
                return transactions;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing transaction: ', error))
        .finally(() => hideLoader());
    }
  };

  const handleDeleteTransaction = () => {
    if (selectedTransactionID) {
      showLoader();
      deleteTransaction(selectedTransactionID)
        .then(() => {
          setTransactionsData(
            _.reject(transactionsData, {
              id: selectedTransactionID,
            }) as TransactionModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting transaction: ', error))
        .finally(() => hideLoader());
    }
  };

  const openTransactionForm = () => {
    let title: string;
    let currentTransaction: Partial<TransactionModel> = {
      branchId: branch?.id,
      projectId: 0,
      employeeId: employee?.id,
      accountId: 0,
      customerId: 0,
      supplierId: 0,
      vehicleId: 0,
      inventoryId: 0,
      category: '',
      transactionType: '',
      transactionStatus: '',
      gstIn: '',
      amount: 0,
      gst: 0,
      iGst: 0,
      cGst: 0,
      sGst: 0,
      notes: '',
    };
    let callback: (transaction: Partial<TransactionModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Transaction';
        callback = handleAddTransaction;
        break;
      case 'edit':
        title = 'Edit Transaction';
        callback = handleEditTransaction;
        currentTransaction = _.chain(transactionsData)
          .find(transaction => transaction.id === selectedTransactionID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Transaction';
        callback = handleDeleteTransaction;
        currentTransaction = _.chain(transactionsData)
          .find(transaction => transaction.id === selectedTransactionID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Transaction';
        callback = handleAddTransaction;
    }
    return (
      <Dialog
        open={openForm}
        fullScreen={fullScreen}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: fullScreen ? 0 : 4 } }}
        sx={{ backdropFilter: 'blur(2px)' }}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <TransactionForm
            mode={formMode}
            initFormState={currentTransaction}
            accountsList={accountsList}
            employeesList={employeesList}
            projectsList={projectsList}
            customersList={customersList}
            suppliersList={suppliersList}
            vehiclesList={vehiclesList}
            inventoriesList={inventoriesList}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'accountId',
      headerName: 'ACCOUNT',
      flex: 0.5,
      type: 'singleSelect',
      valueOptions: _.map(accountsList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = _.find(accountsList, record => {
          return record.id == (value as string);
        });
        return result ? result.name : '...';
      },
    },
    {
      field: 'projectId',
      headerName: 'PROJECT',
      flex: 1,
      type: 'singleSelect',
      valueOptions: _.map(projectsList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = _.find(projectsList, record => {
          return record.id == (value as string);
        });
        return result ? result.name : '...';
      },
    },
    {
      field: 'category',
      headerName: 'CATEGORY',
      flex: 0.8,
      type: 'singleSelect',
      valueOptions: _.map(TransactionFormCategoryOptions, record => {
        return record.value;
      }),
      getOptionLabel: value => {
        const result = _.find(TransactionFormCategoryOptions, record => {
          return record.value == (value as string);
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'transactionType',
      headerName: 'TYPE',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(TransactionFormTypeOptions, record => {
        return record.value;
      }),
      getOptionLabel: value => {
        const result = _.find(TransactionFormTypeOptions, record => {
          return record.value == (value as string);
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'transactionStatus',
      headerName: 'STATUS',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(TransactionFormStatusOptions, record => {
        return record.value;
      }),
      getOptionLabel: value => {
        const result = _.find(TransactionFormStatusOptions, record => {
          return record.value == (value as string);
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'amount',
      headerName: 'AMOUNT',
      flex: 0.8,
      type: 'number',
      valueGetter: value => value && format(value),
    },
    {
      field: 'gst',
      headerName: 'GST',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && format(value),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ACTIONS',
      flex: 0.4,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              setSelectedTransactionID(id);
              handleOpen('edit');
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            color="inherit"
            onClick={() => {
              setSelectedTransactionID(id);
              handleOpen('delete');
            }}
          />,
        ];
      },
    },
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ pb: 1 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: 2 }}
          onClick={() => handleOpen('create')}>
          <AddIcon /> Add
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>Transactions</title>
      </Helmet>

      <Paper sx={{ height: '50%', minHeight: 400, borderRadius: 4 }}>
        <DataGrid
          loading={loading}
          rows={transactionsData}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableRowSelectionOnClick
          sx={{ px: 2, pt: 2, borderRadius: 4 }}
        />
      </Paper>

      {openForm && openTransactionForm()}
    </>
  );
};

export default React.memo(Transactions);
