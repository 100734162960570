import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  createVehicle,
  deleteVehicle,
  VehicleModel,
  getVehicles,
  updateVehicle,
} from 'apis/VehicleApi';
import { useLoader } from 'contexts/LoaderContext';
import VehicleForm from './VehicleForm';
import { pageSizeOptions } from 'constants/PaginationOptions';
import {
  CurrentDate,
  DateFormat,
  ParseEpochDateString,
} from 'utils/EpochConverter';
import { useAuth } from 'contexts/AuthContext';

export type FormModeType = 'create' | 'edit' | 'delete';

const Vehicles = () => {
  const { branch } = useAuth();
  const { loading, showLoader, hideLoader } = useLoader();

  const [selectedVehicleID, setSelectedVehicleID] = useState<GridRowId>();
  const [vehiclesData, setVehiclesData] = useState<VehicleModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const fetchData = () => {
    showLoader();
    getVehicles({
      branchId: branch?.id,
      page: page + 1,
      pageSize: pageSize,
      sort: sortColumn + ':' + sortOrder,
      filter: filterField + ':' + filterOperator + ':' + filterValue,
    })
      .then(response => {
        setVehiclesData(response.rows);
        setRowCount(response.total);
      })
      .catch(error => console.error('Error Fetching Time Sheets: ', error))
      .finally(() => hideLoader());
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  const handleAddVehicle = (newVehicle: Partial<VehicleModel>) => {
    showLoader();
    createVehicle(newVehicle as Omit<VehicleModel, 'id'>)
      .then(response => {
        setVehiclesData(prevVehicles => [...prevVehicles, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding time sheet: ', error))
      .finally(() => hideLoader());
  };

  const handleEditVehicle = (editedVehicle: Partial<VehicleModel>) => {
    showLoader();
    if (selectedVehicleID) {
      updateVehicle(selectedVehicleID, editedVehicle)
        .then(response => {
          setVehiclesData(
            _.chain(vehiclesData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(vehicles => {
                const index = _.findIndex(
                  vehicles,
                  trans => trans.id === selectedVehicleID,
                );
                if (index !== -1) vehicles[index] = response;
                return vehicles;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing time sheet: ', error))
        .finally(() => hideLoader());
    }
  };

  const handleDeleteVehicle = () => {
    if (selectedVehicleID) {
      showLoader();
      deleteVehicle(selectedVehicleID)
        .then(() => {
          setVehiclesData(
            _.reject(vehiclesData, {
              id: selectedVehicleID,
            }) as VehicleModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting time sheet: ', error))
        .finally(() => hideLoader());
    }
  };

  const openVehicleForm = () => {
    let title: string;
    let currentVehicle: Partial<VehicleModel> = {
      branchId: branch?.id,
      name: '',
      registration: '',
      fitness: CurrentDate(),
      insurance: CurrentDate(),
      tax: CurrentDate(),
      tyreDate: CurrentDate(),
      tyreOdo: 0,
      oilDate: CurrentDate(),
      oilOdo: 0,
      filterDate: CurrentDate(),
      filterOdo: 0,
      greaseDate: CurrentDate(),
      greaseOdo: 0,
      adBlueDate: CurrentDate(),
      adBlueOdo: 0,
      serviceDate: CurrentDate(),
      serviceOdo: 0,
      odometer: 0,
      notes: '',
    };
    let callback: (vehicle: Partial<VehicleModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Vehicle';
        callback = handleAddVehicle;
        break;
      case 'edit':
        title = 'Edit Vehicle';
        callback = handleEditVehicle;
        currentVehicle = _.chain(vehiclesData)
          .find(vehicle => vehicle.id === selectedVehicleID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Vehicle';
        callback = handleDeleteVehicle;
        currentVehicle = _.chain(vehiclesData)
          .find(vehicle => vehicle.id === selectedVehicleID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Vehicle';
        callback = handleAddVehicle;
    }
    return (
      <Dialog
        open={openForm}
        fullScreen={fullScreen}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: fullScreen ? 0 : 4 } }}
        sx={{ backdropFilter: 'blur(2px)' }}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <VehicleForm
            mode={formMode}
            initFormState={currentVehicle}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 0.4,
      type: 'string',
    },
    {
      field: 'registration',
      headerName: 'REGISTRATION',
      flex: 0.4,
      type: 'string',
    },
    {
      field: 'odometer',
      headerName: 'ODOMETER',
      flex: 0.4,
      type: 'number',
    },
    {
      field: 'fitness',
      headerName: 'FITNESS',
      flex: 0.4,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'insurance',
      headerName: 'INSURANCE',
      flex: 0.4,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'tax',
      headerName: 'TAX',
      flex: 0.4,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'serviceDate',
      headerName: 'SERVICE',
      flex: 0.4,
      type: 'date',
      valueGetter: value => value && new Date(value * 1000),
      renderCell: params => {
        return <span>{DateFormat(params.value / 1000)}</span>;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ACTIONS',
      flex: 0.4,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              setSelectedVehicleID(id);
              handleOpen('edit');
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            color="inherit"
            onClick={() => {
              setSelectedVehicleID(id);
              handleOpen('delete');
            }}
          />,
        ];
      },
    },
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ pb: 1 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: 2 }}
          onClick={() => handleOpen('create')}>
          <AddIcon /> Add
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>Vehicles</title>
      </Helmet>

      <Paper sx={{ height: '50%', minHeight: 400, borderRadius: 4 }}>
        <DataGrid
          loading={loading}
          rows={vehiclesData}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                if (['day'].includes(filterModel.field)) {
                  setFilterValue(ParseEpochDateString(filterModel.value));
                } else {
                  setFilterValue(filterModel.value);
                }
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableRowSelectionOnClick
          sx={{ px: 2, pt: 2, borderRadius: 4 }}
        />
      </Paper>

      {openForm && openVehicleForm()}
    </>
  );
};

export default React.memo(Vehicles);
