import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  createOperation,
  deleteOperation,
  OperationModel,
  getOperations,
  updateOperation,
} from 'apis/OperationApi';
import { useLoader } from 'contexts/LoaderContext';
import OperationForm from './OperationForm';
import axios from 'apis/AxiosConfig';
import {
  listCustomers,
  listEmployees,
  listInventories,
  listProjects,
  listSuppliers,
  listVehicles,
  OptionsType,
} from 'apis/ListApi';
import { pageSizeOptions } from 'constants/PaginationOptions';
import { useAuth } from 'contexts/AuthContext';
import { OperationFormTypeOptions } from 'constants/DropDownOptions';
import format from 'utils/CurrencyFormatter';

export type FormModeType = 'create' | 'edit' | 'delete';

const Operations = () => {
  const { branch, employee } = useAuth();
  const { loading, showLoader, hideLoader } = useLoader();

  const [selectedOperationID, setSelectedOperationID] = useState<GridRowId>();
  const [operationsData, setOperationsData] = useState<OperationModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const [employeesList, setEmployeesList] = useState<OptionsType[]>([]);
  const [projectsList, setProjectsList] = useState<OptionsType[]>([]);
  const [customersList, setCustomersList] = useState<OptionsType[]>([]);
  const [suppliersList, setSuppliersList] = useState<OptionsType[]>([]);
  const [vehiclesList, setVehiclesList] = useState<OptionsType[]>([]);
  const [inventoriesList, setInventoriesList] = useState<OptionsType[]>([]);

  const fetchData = () => {
    showLoader();
    getOperations({
      branchId: branch?.id,
      page: page + 1,
      pageSize: pageSize,
      sort: sortColumn + ':' + sortOrder,
      filter: filterField + ':' + filterOperator + ':' + filterValue,
    })
      .then(response => {
        setOperationsData(response.rows);
        setRowCount(response.total);
      })
      .catch(error => console.error('Error Fetching Operations: ', error))
      .finally(() => hideLoader());
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  useEffect(() => {
    axios
      .all([
        listEmployees({ branchId: branch?.id }),
        listProjects({ branchId: branch?.id }),
        listCustomers({ branchId: branch?.id }),
        listSuppliers({ branchId: branch?.id }),
        listVehicles({ branchId: branch?.id }),
        listInventories({ branchId: branch?.id }),
      ])
      .then(
        axios.spread(
          (
            employeesList,
            projectsList,
            customersList,
            suppliersList,
            vehiclesList,
            inventoriesList,
          ) => {
            setEmployeesList(employeesList);
            setProjectsList(projectsList);
            setCustomersList(customersList);
            setSuppliersList(suppliersList);
            setVehiclesList(vehiclesList);
            setInventoriesList(inventoriesList);
          },
        ),
      );
  }, []);

  const handleAddOperation = (newOperation: Partial<OperationModel>) => {
    showLoader();
    createOperation(newOperation as Omit<OperationModel, 'id'>)
      .then(response => {
        setOperationsData(prevOperations => [...prevOperations, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding operation: ', error))
      .finally(() => hideLoader());
  };

  const handleEditOperation = (editedOperation: Partial<OperationModel>) => {
    showLoader();
    if (selectedOperationID) {
      updateOperation(selectedOperationID, editedOperation)
        .then(response => {
          setOperationsData(
            _.chain(operationsData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(operations => {
                const index = _.findIndex(
                  operations,
                  rem => rem.id === selectedOperationID,
                );
                if (index !== -1) operations[index] = response;
                return operations;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing operation: ', error))
        .finally(() => hideLoader());
    }
  };

  const handleDeleteOperation = () => {
    if (selectedOperationID) {
      showLoader();
      deleteOperation(selectedOperationID)
        .then(() => {
          setOperationsData(
            _.reject(operationsData, {
              id: selectedOperationID,
            }) as OperationModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting operation: ', error))
        .finally(() => hideLoader());
    }
  };

  const openOperationForm = () => {
    let title: string;
    let currentOperation: Partial<OperationModel> = {
      branchId: branch?.id,
      employeeId: employee?.id,
      inventoryId: 0,
      projectId: 0,
      customerId: 0,
      supplierId: 0,
      vehicleId: 0,
      operationType: '',
      quantity: 0,
      price: 0,
      notes: '',
    };
    let callback: (operation: Partial<OperationModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Operation';
        callback = handleAddOperation;
        break;
      case 'edit':
        title = 'Edit Operation';
        callback = handleEditOperation;
        currentOperation = _.chain(operationsData)
          .find(operation => operation.id === selectedOperationID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Operation';
        callback = handleDeleteOperation;
        currentOperation = _.chain(operationsData)
          .find(operation => operation.id === selectedOperationID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Operation';
        callback = handleAddOperation;
    }
    return (
      <Dialog
        open={openForm}
        fullScreen={fullScreen}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: fullScreen ? 0 : 4 } }}
        sx={{ backdropFilter: 'blur(2px)' }}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <OperationForm
            mode={formMode}
            initFormState={currentOperation}
            employeesList={employeesList}
            projectsList={projectsList}
            customersList={customersList}
            suppliersList={suppliersList}
            vehiclesList={vehiclesList}
            inventoriesList={inventoriesList}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'inventoryId',
      headerName: 'INVENTORY',
      flex: 1,
      type: 'singleSelect',
      valueOptions: _.map(inventoriesList, record => {
        return record.id;
      }),
      getOptionLabel: value => {
        const result = _.find(inventoriesList, record => {
          return record.id == (value as string);
        });
        return result ? result.name : '...';
      },
    },
    {
      field: 'operationType',
      headerName: 'TYPE',
      flex: 0.8,
      type: 'string',
    },
    {
      field: 'quantity',
      headerName: 'QUANTITY',
      flex: 0.8,
      type: 'number',
    },
    {
      field: 'price',
      headerName: 'PRICE',
      flex: 0.8,
      type: 'number',
      valueGetter: value => value && format(value),
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 0.4,
      type: 'singleSelect',
      valueOptions: _.map(OperationFormTypeOptions, record => {
        return record.value;
      }),
      getOptionLabel: value => {
        const result = _.find(OperationFormTypeOptions, record => {
          return record.value == (value as string);
        });
        return result ? result.label : '...';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ACTIONS',
      flex: 0.4,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              setSelectedOperationID(id);
              handleOpen('edit');
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            color="inherit"
            onClick={() => {
              setSelectedOperationID(id);
              handleOpen('delete');
            }}
          />,
        ];
      },
    },
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ pb: 1 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: 2 }}
          onClick={() => handleOpen('create')}>
          <AddIcon /> Add
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>Operations</title>
      </Helmet>

      <Paper sx={{ height: '50%', minHeight: 400, borderRadius: 4 }}>
        <DataGrid
          loading={loading}
          rows={operationsData}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableRowSelectionOnClick
          sx={{ px: 2, pt: 2, borderRadius: 4 }}
        />
      </Paper>

      {openForm && openOperationForm()}
    </>
  );
};

export default React.memo(Operations);
