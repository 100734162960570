import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { motion } from 'framer-motion';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { EnabledPages } from 'constants/EnabledPages';

interface DrawerListType {
  callBackFn: (title: string) => void;
}

const DrawerList = (props: DrawerListType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
    props.callBackFn(EnabledPages[index].title);
    navigate(EnabledPages[index].url);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = EnabledPages.findIndex(
      item => item.url === currentPath,
    );
    if (currentItem !== -1) {
      setSelectedIndex(currentItem);
      props.callBackFn(EnabledPages[currentItem].title);
    }
  }, [location.pathname, props]);

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <List component="nav">
      {_.map(EnabledPages, (item, index) => (
        <ListItemButton
          key={index}
          selected={selectedIndex === index}
          disabled={item.disabled}
          onClick={() => handleListItemClick(index)}>
          <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
            <ListItemIcon sx={{ pl: largeScreen ? 1 : 0 }}>
              <Tooltip title={item.title} placement="right" arrow>
                {<item.icon />}
              </Tooltip>
            </ListItemIcon>
          </motion.div>
          <ListItemText primary={item.title} />
        </ListItemButton>
      ))}
    </List>
  );
};

export default React.memo(DrawerList);
