import { GridRowId } from '@mui/x-data-grid';

import axios from 'apis/AxiosConfig';

export interface PaginatedVehicleModel {
  rows: VehicleModel[];
  total: number;
}

export interface VehicleModel {
  id: number;
  branchId: number;
  name: string;
  registration: string;
  fitness: number;
  insurance: number;
  tax: number;
  tyreDate: number;
  tyreOdo: number;
  oilDate: number;
  oilOdo: number;
  filterDate: number;
  filterOdo: number;
  greaseDate: number;
  greaseOdo: number;
  adBlueDate: number;
  adBlueOdo: number;
  serviceDate: number;
  serviceOdo: number;
  odometer: number;
  notes: string;
  createdAt: number;
  updatedAt: number;
}

export const getVehicles = async (
  paramObj: object = {},
): Promise<PaginatedVehicleModel> => {
  const response = await axios.get<PaginatedVehicleModel>(`/vehicles`, {
    params: paramObj,
  });
  return response.data;
};

export const getVehicleById = async (id: number): Promise<VehicleModel> => {
  const response = await axios.get<VehicleModel>(`/vehicles/${id}`);
  return response.data;
};

export const createVehicle = async (
  vehicleData: Omit<VehicleModel, 'id'>,
): Promise<VehicleModel> => {
  const response = await axios.post<VehicleModel>(`/vehicles`, vehicleData);
  return response.data;
};

export const updateVehicle = async (
  id: GridRowId,
  vehicleData: Partial<VehicleModel>,
): Promise<VehicleModel> => {
  const response = await axios.put<VehicleModel>(
    `/vehicles/${id}`,
    vehicleData,
  );
  return response.data;
};

export const deleteVehicle = async (id: GridRowId): Promise<void> => {
  await axios.delete(`/vehicles/${id}`);
};
