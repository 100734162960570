import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import {
  createSupplier,
  deleteSupplier,
  SupplierModel,
  getSuppliers,
  updateSupplier,
} from 'apis/SupplierApi';
import { useLoader } from 'contexts/LoaderContext';
import SupplierForm from './SupplierForm';
import format from 'utils/CurrencyFormatter';
import { pageSizeOptions } from 'constants/PaginationOptions';
import { useAuth } from 'contexts/AuthContext';

export type FormModeType = 'create' | 'edit' | 'delete';

const Suppliers = () => {
  const { branch } = useAuth();
  const { loading, showLoader, hideLoader } = useLoader();

  const [selectedSupplierID, setSelectedSupplierID] = useState<GridRowId>();
  const [suppliersData, setSuppliersData] = useState<SupplierModel[]>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeOptions[2]);
  const [rowCount, setRowCount] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState<string | null | undefined>('');
  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormModeType>();

  const fetchData = () => {
    showLoader();
    getSuppliers({
      branchId: branch?.id,
      page: page + 1,
      pageSize: pageSize,
      sort: sortColumn + ':' + sortOrder,
      filter: filterField + ':' + filterOperator + ':' + filterValue,
    })
      .then(response => {
        setSuppliersData(response.rows);
        setRowCount(response.total);
      })
      .catch(error => console.error('Error Fetching Suppliers: ', error))
      .finally(() => hideLoader());
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    pageSize,
    sortColumn,
    sortOrder,
    filterField,
    filterOperator,
    filterValue,
  ]);

  const handleAddSupplier = (newSupplier: Partial<SupplierModel>) => {
    showLoader();
    createSupplier(newSupplier as Omit<SupplierModel, 'id'>)
      .then(response => {
        setSuppliersData(prevSuppliers => [...prevSuppliers, response]);
        handleClose();
      })
      .catch(error => console.error('Error adding supplier: ', error))
      .finally(() => hideLoader());
  };

  const handleEditSupplier = (editedSupplier: Partial<SupplierModel>) => {
    showLoader();
    if (selectedSupplierID) {
      updateSupplier(selectedSupplierID, editedSupplier)
        .then(response => {
          setSuppliersData(
            _.chain(suppliersData)
              .cloneDeep() // Clone the array to maintain immutability
              .thru(suppliers => {
                const index = _.findIndex(
                  suppliers,
                  acc => acc.id === selectedSupplierID,
                );
                if (index !== -1) suppliers[index] = response;
                return suppliers;
              })
              .value(),
          );
          handleClose();
        })
        .catch(error => console.error('Error editing supplier: ', error))
        .finally(() => hideLoader());
    }
  };

  const handleDeleteSupplier = () => {
    if (selectedSupplierID) {
      showLoader();
      deleteSupplier(selectedSupplierID)
        .then(() => {
          setSuppliersData(
            _.reject(suppliersData, {
              id: selectedSupplierID,
            }) as SupplierModel[],
          );
          handleClose();
        })
        .catch(error => console.error('Error deleting supplier: ', error))
        .finally(() => hideLoader());
    }
  };

  const openSupplierForm = () => {
    let title: string;
    let currentSupplier: Partial<SupplierModel> = {
      branchId: branch?.id,
      name: '',
      phone1: '',
      phone2: '',
      phone3: '',
      address: '',
      gstIn: '',
      balance: 0,
      limit: 0,
      gst: 0,
      iGst: 0,
      cGst: 0,
      sGst: 0,
      notes: '',
    };
    let callback: (supplier: Partial<SupplierModel>) => void;

    switch (formMode) {
      case 'create':
        title = 'New Supplier';
        callback = handleAddSupplier;
        break;
      case 'edit':
        title = 'Edit Supplier';
        callback = handleEditSupplier;
        currentSupplier = _.chain(suppliersData)
          .find(transaction => transaction.id === selectedSupplierID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      case 'delete':
        title = 'Delete Supplier';
        callback = handleDeleteSupplier;
        currentSupplier = _.chain(suppliersData)
          .find(transaction => transaction.id === selectedSupplierID)
          .omit(['createdAt', 'updatedAt'])
          .value();
        break;
      default:
        title = 'New Supplier';
        callback = handleAddSupplier;
    }
    return (
      <Dialog
        open={openForm}
        fullScreen={fullScreen}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: fullScreen ? 0 : 4 } }}
        sx={{ backdropFilter: 'blur(2px)' }}>
        <Typography fontWeight={'700'} fontSize={'2rem'} pt={2} pl={3}>
          {title}
        </Typography>
        <DialogContent sx={{ pt: 0 }}>
          <SupplierForm
            mode={formMode}
            initFormState={currentSupplier}
            callbackFn={callback}
            handleCloseFn={handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'phone1',
      headerName: 'Phone',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'gstIn',
      headerName: 'GSTIN',
      flex: 0.6,
      type: 'string',
    },
    {
      field: 'limit',
      headerName: 'LIMIT',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && format(value),
    },
    {
      field: 'balance',
      headerName: 'BALANCE',
      flex: 0.6,
      type: 'number',
      valueGetter: value => value && format(value),
    },
    {
      field: 'gst',
      headerName: 'GST',
      flex: 0.5,
      type: 'number',
      valueGetter: value => value && format(value),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ACTIONS',
      flex: 0.4,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              setSelectedSupplierID(id);
              handleOpen('edit');
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon color="error" />}
            label="Delete"
            color="inherit"
            onClick={() => {
              setSelectedSupplierID(id);
              handleOpen('delete');
            }}
          />,
        ];
      },
    },
  ];

  const handleOpen = (mode: FormModeType) => {
    setFormMode(mode);
    setOpenForm(true);
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ pb: 1 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: 2 }}
          onClick={() => handleOpen('create')}>
          <AddIcon /> Add
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Change density' } }}
        />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Export' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>Suppliers</title>
      </Helmet>

      <Paper sx={{ height: '50%', minHeight: 400, borderRadius: 4 }}>
        <DataGrid
          loading={loading}
          rows={suppliersData}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={model => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          sortingMode="server"
          onSortModelChange={model => {
            _.map(model, sortItem => {
              setSortColumn(sortItem.field);
              setSortOrder(sortItem.sort);
            });
          }}
          filterMode="server"
          filterDebounceMs={1000}
          onFilterModelChange={model => {
            _.map(model.items, filterModel => {
              if (filterModel.value != undefined) {
                setFilterField(filterModel.field);
                setFilterOperator(filterModel.operator);
                setFilterValue(filterModel.value);
              }
            });
            if (_.isEmpty(model.items)) {
              setFilterField('');
              setFilterOperator('');
              setFilterValue('');
            }
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          disableRowSelectionOnClick
          sx={{ px: 2, pt: 2, borderRadius: 4 }}
        />
      </Paper>

      {openForm && openSupplierForm()}
    </>
  );
};

export default React.memo(Suppliers);
