import React, { useState } from 'react';
import moment, { Moment } from 'moment';

import { Box, Button, FormControl, Grid, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { VehicleModel } from 'apis/VehicleApi';
import { useLoader } from 'contexts/LoaderContext';
import { FormModeType } from './Vehicles';
import { DatePicker } from '@mui/x-date-pickers';
import { DateFormatString } from 'constants/DateTimeFormat';

interface VehicleFormType {
  mode: FormModeType | undefined;
  initFormState: Partial<VehicleModel>;
  callbackFn: (vehicle: Partial<VehicleModel>) => void;
  handleCloseFn: () => void;
}

const VehicleForm = (props: VehicleFormType) => {
  const [formState, setFormState] = useState(props.initFormState);
  const { loading } = useLoader();
  const disabled = props.mode === 'delete';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeUpperCase = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value.toUpperCase(),
    }));
  };

  const handleDateChange = (name: string) => (newDate: Moment | null) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: newDate?.unix(),
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.callbackFn(formState as Partial<VehicleModel>);
  };

  const btnName = () => {
    switch (props.mode) {
      case 'create':
        return 'Add';
      case 'edit':
        return 'Save';
      case 'delete':
        return 'Delete';
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              type="text"
              name="name"
              autoFocus
              value={formState.name}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Registration"
              type="text"
              name="registration"
              autoFocus
              value={formState.registration}
              onChange={handleChangeUpperCase}
              required={!disabled}
              disabled={disabled}
              inputProps={{
                maxLength: 10,
                style: {
                  textTransform: 'uppercase',
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Odometer"
              type="number"
              name="odometer"
              value={formState.odometer}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>KMS/HRS </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Fitness"
              name="fitness"
              value={moment.unix(formState.fitness as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('fitness')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Insurance"
              name="insurance"
              value={moment.unix(formState.insurance as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('insurance')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Tax"
              name="tax"
              value={moment.unix(formState.tax as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('tax')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Tyre (Date)"
              name="tyreDate"
              value={moment.unix(formState.tyreDate as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('tyreDate')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Tyre (Odometer)"
              type="number"
              name="tyreOdo"
              value={formState.tyreOdo}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>KMS/HRS </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Oil (Date)"
              name="oilDate"
              value={moment.unix(formState.oilDate as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('oilDate')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Oil (Odometer)"
              type="number"
              name="oilOdo"
              value={formState.oilOdo}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>KMS/HRS </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Filter (Date)"
              name="filterDate"
              value={moment.unix(formState.filterDate as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('filterDate')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Filter (Odometer)"
              type="number"
              name="filterOdo"
              value={formState.filterOdo}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>KMS/HRS </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Grease (Date)"
              name="greaseDate"
              value={moment.unix(formState.greaseDate as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('greaseDate')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Grease (Odometer)"
              type="number"
              name="greaseOdo"
              value={formState.greaseOdo}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>KMS/HRS </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="AdBlue (Date)"
              name="adBlueDate"
              value={moment.unix(formState.adBlueDate as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('adBlueDate')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="AdBlue (Odometer)"
              type="number"
              name="adBlueOdo"
              value={formState.adBlueOdo}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>KMS/HRS </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Service (Date)"
              name="serviceDate"
              value={moment.unix(formState.serviceDate as unknown as number)}
              format={DateFormatString}
              onChange={handleDateChange('serviceDate')}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Service (Odometer)"
              type="number"
              name="serviceOdo"
              value={formState.serviceOdo}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              InputProps={{
                startAdornment: <pre>KMS/HRS </pre>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} pb={1}>
          <FormControl fullWidth margin="normal">
            <TextField
              multiline
              label="Notes"
              name="notes"
              value={formState.notes}
              onChange={handleChange}
              required={!disabled}
              disabled={disabled}
              minRows={3}
              maxRows={8}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} textAlign={'right'} pt={1}>
          <Button onClick={props.handleCloseFn} variant="text" sx={{ mr: 4 }}>
            {'Close'}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ px: 6, borderRadius: 2 }}
            loading={loading}
            loadingIndicator={'Loading...'}>
            {btnName()}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(VehicleForm);
